import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div``

const TaskName = styled.h3`
	color: #000;
	margin: 1em 0;
`

const DescriptionBlock = styled.div`
	border: 1px solid #e5e5e5;
	padding: 1em;
`

const P = styled.p`
	color: #000;
`

const Header = ({ name, description, task }) => {
	return (
		<Wrapper>
			<TaskName>{name}</TaskName>
			<DescriptionBlock>
				<P>{description}</P>
				<br />
				<P>{task}</P>
			</DescriptionBlock>
		</Wrapper>
	)
}

Header.propTypes = {}

export default Header
