import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'
import cloudUploadIcon from '../assets/images/cloud-network-upload-svgrepo-com.svg'
import Dnd from './dnd'

const StyledUploadIcon = styled.img.attrs(props => ({
	src: cloudUploadIcon,
}))`
	width: 100%;
	height: 100%;
	max-width: 66px;
	max-height: 56px;
	margin: 1em 0;
`

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16,
	maxHeight: '229px',
	overflow: 'scroll',
	overflowX: 'hidden',
}

const StyledPaper = styled(Paper)`
	padding: 1em;
	display: flex;
	height: 100vh;
	width: 100%;
	max-height: 370px;
	min-width: 400px;
	border: 3px dashed #c8cbce;
	position: relative;
`

const HelperText = styled.h3`
	font-size: 2em;
	color: #949fa6;
`

const MoreInfo = styled.p`
	color: #949fa6;
	margin: 1em 0 0 0;
	cursor: pointer;
	text-align: center;
`

const Text = styled.p`
	color: #949fa6;
	margin: 2em 0;
`

const StyledDropzone = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`

const BrowseButton = styled.button`
	padding: 0.5em;
	border: 2px solid #53c1fb;
	color: #53c1fb;
	font-size: 1.3em;
	text-align: center;
`

function Previews({ getImagesCount, getFiles, images = [], onDelete }) {
	const [files, setFiles] = useState(images)
	const {
		getRootProps,
		getInputProps,
		isDragReject,
		isFileTooLarge,
	} = useDropzone({
		accept: 'image/*',
		onDrop: acceptedFiles => {
			const newFiles = [
				...acceptedFiles
					.filter(file => files.some(e => e.name === file.name) === false)
					.map(file =>
						Object.assign(file, {
							src: URL.createObjectURL(file),
							id: file.name + Math.random().toString(),
						}),
					),
				...files,
			]
			setFiles(newFiles)
		},
		maxSize: 1024 * 1000 * 10,
		minSize: 0,
	})

	const [sortedFiles, setSortedFiles] = useState([])

	useEffect(() => {
		getImagesCount(files.length)
		getFiles(sortedFiles.length === 0 ? files : sortedFiles)
	}, [files, sortedFiles.length])

	useEffect(() => {
		setFiles([...files, ...images])
	}, [images.length])

	function deleteImage(id) {
		const idx = files.findIndex(e => e.id === id)
		if (idx !== -1) {
			onDelete(files[idx])
			setFiles([...files.slice(0, idx), ...files.slice(idx + 1)])
		}
	}

	useEffect(() => {
		return () => files.forEach(file => URL.revokeObjectURL(file.preview))
	}, [])

	const [items, setItems] = useState([])

	useEffect(() => {
		setItems(files)
	}, [files.length])

	return (
		<StyledPaper elevation={3}>
			<StyledDropzone {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				{isDragReject && 'Тип файла не поддерживается!'}
				{isFileTooLarge && (
					<div className="text-danger mt-2">Файл слишком большой.</div>
				)}
				{files.length === 0 && (
					<>
						<StyledUploadIcon />
						<HelperText>Перетащите файлы сюда</HelperText> <Text>или</Text>
						<BrowseButton type="button">Выбрать файлы</BrowseButton>
					</>
				)}
				<aside
					onClick={event => event.stopPropagation()}
					style={thumbsContainer}
				>
					<Dnd
						deleteItem={deleteImage}
						getSortedItems={sortedItems => {
							if (sortedItems.length >= files.length) {
								getFiles(sortedItems)
							}
						}}
						items={items}
					/>
				</aside>
				{files.length !== 0 && (
					<MoreInfo>
						Чтобы добавить еще изображения - нажмите на этот текст или
						перетащите их в эту область
					</MoreInfo>
				)}
			</StyledDropzone>
		</StyledPaper>
	)
}

export default Previews
