import React from 'react'
import Header from '../Header'
import { StyledContainer } from '../../styled'
import Profile from '../Profile'
import Footer from '../Footer'

const ProfilePage = () => {
	return (
		<StyledContainer>
			<Header />
			<Profile />
			<Footer />
		</StyledContainer>
	)
}

ProfilePage.propTypes = {}

ProfilePage.defaultProps = {}

export default ProfilePage
