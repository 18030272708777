import axios, { AxiosInstance } from 'axios'

import config from '../config'

export default class TerraService {
	_client: AxiosInstance = axios.create({
		baseURL: config.apiPath,
		timeout: 300000,
	})

	phoneLogin = async (phone, code, reCaptcha) =>
		await this._request('auth/phoneLogin', 'post', { phone, code, reCaptcha })

	emailPasswordLogin = async (email, password, reCaptcha) =>
		await this._request('auth/emailPasswordlogin', 'post', {
			email,
			password,
			reCaptcha,
		})

	// todo reCaptcha
	userLogout = async () =>
		await this._request('auth/log-out', 'post', {
			accessToken: localStorage.getItem('accessToken'),
		})

	createUser = async (
		email,
		phone,
		password,
		subscribe,
		reCaptcha,
		promo,
		regType,
	) =>
		await this._request('auth/register', 'post', {
			email,
			phone,
			password,
			subscribe,
			reCaptcha,
			promo,
			regType,
		})

	createPayment = async tariff =>
		await this._request('payment/create-payment', 'post', { tariff })

	// todo reCaptcha
	updateTariff = async tariff =>
		await this._request('user/update-tariff', 'post', { tariff })

	// todo reCaptcha
	confirmUser = async token =>
		await this._request(`auth/login/${token}`, 'post')

	// todo reCaptcha
	sendConfirmEmail = async email =>
		await this._request(`auth/send-confirm-email`, 'post', { email })

	// todo reCaptcha
	updatePhone = async phone =>
		await this._request('auth/update-phone', 'post', { phone })

	// todo reCaptcha
	updateEmail = async email =>
		await this._request('auth/update-email', 'post', { email })

	// todo reCaptcha
	updatePassword = async passwords =>
		await this._request('user/update-password', 'post', passwords)

	requestAuth = async (phoneOrEmail, reCaptcha) =>
		await this._request('auth/request-auth', 'post', {
			...phoneOrEmail,
			reCaptcha,
		})

	requestChangePassword = async (email, reCaptcha) =>
		await this._request('auth/request-change-password', 'post', {
			email,
			reCaptcha,
		})

	submitChangedPassword = async (token, password, reCaptcha) =>
		await this._request(`auth/change-password/${token}`, 'post', {
			password,
			reCaptcha,
		})

	// todo reCaptcha
	getUser = async () => await this._request(`user/`, 'get')

	// todo reCaptcha?
	getTasks = async (params, filter) =>
		await this._request(
			`tasks${this._addParams(params)}&${Object.keys(filter)
				.filter(e => filter[e])
				.map(e => `statuses=${e}`)
				.join('&')}`,
			'get',
		)

	// todo reCaptcha
	getTask = async id => await this._request(`tasks/${id}`, 'get')

	// todo reCaptcha
	createTask = async data => await this._request('tasks', 'post', data)

	// todo reCaptcha
	updateTask = async (data, id) =>
		await this._request(`tasks/${id}`, 'patch', data)

	// todo reCaptcha
	getExercises = async params =>
		await this._request(`exercises${this._addParams(params)}`, 'get')

	// todo reCaptcha
	getExercise = async id => await this._request(`exercises/${id}`, 'get')

	// todo reCaptcha
	createExercise = async data => await this._request('exercises', 'post', data)

	// todo reCaptcha
	updateExercise = async (data, id) =>
		await this._request(`exercises/${id}`, 'patch', data)

	// todo reCaptcha
	deleteExercise = async id => await this._request(`exercises/${id}`, 'delete')

	async _request(url, method, body) {
		this._applyToken()
		const res = await this._client[method](url, body)
		return res.data
	}

	_applyToken() {
		const accessToken = localStorage.getItem('accessToken')

		if (accessToken) {
			this._client.defaults.headers.authorization = `Bearer ${accessToken}`
		}
	}

	_addParams(params = {}) {
		return Object.keys(params)
			.filter(key => params[key])
			.map((key, index) => {
				const splitSymbol = index === 0 ? '?' : '&'
				let url = `${splitSymbol}${key}=${params[key]}`

				if (key.toLocaleLowerCase().indexOf('list') !== -1) {
					url = `${splitSymbol}${key}=`

					params[key].forEach(param => {
						url += `${param},`
					})
				}

				return url
			})
			.join('')
	}
}
