import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import StoreContext from '../StoreContext'

const HasPermission = ({ skipVerification, children }) => {
	const {
		state: { user },
	} = useContext(StoreContext)

	if (skipVerification) return children

	return user.roles.includes('admin') ? children : null
}

HasPermission.propTypes = {
	skipVerification: PropTypes.bool,
}

export default HasPermission
