const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const SHOW_LOADING = 'SHOW_LOADING'
const HIDE_LOADING = 'HIDE_LOADING'

export const initialState = {
	user: '',
	tinkoffModal: false,
	loading: false,
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				user: action.user,
			}
		case LOGOUT:
			return {
				...state,
				user: '',
			}
		case SHOW_LOADING:
			return {
				...state,
				loading: true,
			}
		case HIDE_LOADING:
			return {
				...state,
				loading: false,
			}
		default:
			return state
	}
}

export const types = {
	LOGIN,
	LOGOUT,
	HIDE_LOADING,
	SHOW_LOADING,
}
