import React from 'react'
import * as pages from './components/pages'

// By default route is private and ableForLogged user
export default [
	{
		path: '/',
		component: '',
	},
	{
		path: '/login/:token',
		component: pages.LoginPage,
		meta: {
			isPublic: true,
			ableForLogged: false,
		},
	},
	{
		path: '/login',
		component: pages.LoginPage,
		meta: {
			isPublic: true,
			ableForLogged: false,
		},
	},
	{
		path: '/change-password/:token',
		component: pages.ChangePasswordPage,
		meta: {
			isPublic: true,
			ableForLogged: false,
		},
	},
	{
		path: '/change-password',
		component: pages.ChangePasswordPage,
		meta: {
			isPublic: true,
			ableForLogged: false,
		},
	},
	{
		path: '/signup',
		component: pages.SignUpPage,
		meta: {
			isPublic: true,
			ableForLogged: false,
		},
	},
	{
		path: '/tasks',
		component: pages.TasksListPage,
	},
	{
		path: '/help',
		component: pages.HelpPage,
	},
	{
		path: '/profile',
		component: pages.ProfilePage,
	},
	{
		path: '/task',
		component: pages.TaskPage,
	},
	{
		path: '/tasks/:id',
		component: pages.TaskPage,
	},
	{
		path: '/result/:id',
		component: pages.ResultPage,
	},
	{
		path: '/exercises',
		component: pages.ExerciseListPage,
	},
	{
		path: '/exercise',
		component: pages.ExercisePage,
	},
	{
		path: '/exercise/:id',
		component: pages.ExercisePage,
	},
	{
		path: '/do-task/:id',
		component: pages.DoTaskPage,
		meta: {
			isPublic: true,
			ableForLogged: true,
		},
	},
]
