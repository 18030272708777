import React from 'react'
import styled from 'styled-components'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

export const Grid = styled.div`
	width: 650px;
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
`

export const GridImage = styled.img.attrs(({ src }) => ({
	src,
}))`
	flex-grow: 1;
	border: 1px solid white;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
`

const GridItemWrapper = styled.div`
	flex: 0 0 33.3%;
	display: flex;
	justify-content: center;
	align-items: stretch;
	position: relative;

	box-sizing: border-box;

	:before {
		content: '';
		display: table;
		padding-top: 100%;
	}
`

export const DeleteIcon = styled(HighlightOffIcon)`
	color: tomato;
	position: absolute;
	right: 10px;
	top: 10px;
`

export const GridCoverImage = styled.div`
	background: url(${props => props.src}) 0% 0% / 100% no-repeat;
	width: 95%;
	height: 95%;
	border: 1px solid rgba(0, 0, 0, 0.125);
	margin: auto;
	border-radius: 4px;
	background-position: center top;
	background-size: cover;
`

export const GridItem = ({ forwardedRef, ...props }) => (
	<GridItemWrapper ref={forwardedRef} {...props} />
)
