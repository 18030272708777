import React, { useState } from 'react'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import config from '../../config'
import ImagePreview from '../utils/ImagePreview'

const Wrapper = styled.div`
	margin: 1em 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
`

const SelectedCard = styled(Card)`
	width: ${props => (props.cardOnly ? 'none' : '49%')};
	margin: ${props => (props.cardOnly ? '10px' : '10px 0')};
	height: 150px;
`

const SelectedCardImage = styled.div`
	background: url(${props => props.src}) 0% 0% / 100% no-repeat;height: 100%;
	height: 100%;
    background-position: center top;
    background-size: cover;
`

const SelectedCardDescription = styled.textarea`
	border: 1px solid ${props => (props.error ? 'red' : 'rgba(0,0,0,.125);')};
	border-radius: 0.25rem;
	resize: none;
	font-size: 14px;
	padding: 5px;
	width: 100%;
	&::-webkit-scrollbar {
		width: 11px;
	}
	&::-webkit-scrollbar-track {
		background: #cfd8dc;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #90a4ae;
		border-radius: 6px;
		border: 3px solid #cfd8dc;
	}
	scrollbar-width: thin;
	scrollbar-color: #90a4ae #cfd8dc;
	&:disabled {
		background: white;
	}
`

const SelectedCards = styled.div`
	margin: 1em 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
`

const SelectedCardsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	background: #eceeef;
	align-items: center;
	margin-bottom: 1em;
	min-height: 200px;
	max-height: 400px;
	overflow-x: auto;
	flex-direction: row;
	padding: 10px;
	justify-content: space-between;
	-webkit-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);

	&::-webkit-scrollbar {
		width: 11px;
	}
	&::-webkit-scrollbar-track {
		background: #cfd8dc;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #90a4ae;
		border-radius: 6px;
		border: 3px solid #cfd8dc;
	}
	scrollbar-width: thin;
	scrollbar-color: #90a4ae #cfd8dc;
`

const SelectedImageContainer = styled.div`
	width: 100px;
	overflow: hidden;
	margin-right: ${props => (props.cardOnly ? '0' : '10px')};
`

const CardViewer = ({ cards, disabled, cardOnly }) => {
	const selectedCard = cards.map(card => {
		return (
			<SelectedCard
				cardOnly={cardOnly}
				className={cardOnly ? '' : 'selectedCard'}
				key={card._id}
			>
				<Card.Body
					style={{ display: 'flex', padding: cardOnly ? '5px' : '1.25em' }}
				>
					<SelectedImageContainer cardOnly={cardOnly}>
						<ImagePreview src={config.apiUrl + card.url}>
							<SelectedCardImage
								src={config.apiUrl + card.url}
								alt={card.name}
							/>
						</ImagePreview>
					</SelectedImageContainer>

					{!cardOnly ? (
						<SelectedCardDescription
							className={'selectedCardDescription'}
							disabled={disabled}
							value={card.description}
						></SelectedCardDescription>
					) : null}
				</Card.Body>
			</SelectedCard>
		)
	})

	return (
		<Wrapper>
			<SelectedCards>
				<SelectedCardsWrapper>{selectedCard}</SelectedCardsWrapper>
			</SelectedCards>
		</Wrapper>
	)
}

CardViewer.propTypes = {
	cards: PropTypes.array.isRequired,
	disabled: PropTypes.bool.isRequired,
	cardOnly: PropTypes.bool,
}

export {
	CardViewer,
	SelectedCard,
	SelectedCardImage,
	SelectedCardDescription,
	SelectedCards,
	SelectedCardsWrapper,
	SelectedImageContainer,
}
