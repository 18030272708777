import { store } from 'react-notifications-component'

const notify = (type, message, duration = 5000) =>
	store.addNotification({
		title: '',
		message,
		type,
		insert: 'top',
		container: 'top-right',
		animationIn: ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss: {
			duration,
			onScreen: true,
			pauseOnHover: true,
		},
	})

export default notify
