import React from 'react'
import styled, { css } from 'styled-components'
import { TextField } from '@material-ui/core'

const StyledTextField = styled(TextField)`
	input {
		&[type='number']::-webkit-inner-spin-button,
		&[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	fieldset {
		border-color: rgba(0, 0, 0, 0.23) !important;
		border-width: 1px !important;
		border-right: none !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.MuiTextField-root {
		border: none !important;
	}
`

const Wrapper = styled.div`
	display: flex;
	position: relative;
`

const StepperWrapper = styled.div`
	display: flex;
	flex-direction: column;
	right: 0;
`

const StepperItem = styled.div`
	height: 50%;
	text-align: center;
	width: 30px;
	opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};

	&:hover {
		cursor: pointer;
	}

	${({ upper }) =>
		upper &&
		css`
			border: 1px solid rgba(0, 0, 0, 0.23);
			border-bottom: none;
			padding-right: 0;
		`}

	${({ lower }) =>
		lower &&
		css`
			background: #222222;
			color: #fff;
		`}
`

const StepperInput = props => {
	const { handleChange, cardsLimit = 0, value = 0 } = props

	const handleInputChange = e => {
		const val = parseInt(e.target.value)

		if (val > cardsLimit || val < 1) return

		handleChange(val)
	}

	const handleClick = val => {
		if (value + val > 0 && value + val <= cardsLimit) {
			handleChange(value + val)
		}
	}

	const { disabled } = props

	return (
		<Wrapper>
			<StyledTextField
				{...props}
				disabled={disabled}
				onInput={handleInputChange}
				value={value}
				type="number"
			/>

			<StepperWrapper>
				<StepperItem disabled={disabled} onClick={() => handleClick(1)} upper>
					+
				</StepperItem>
				<StepperItem disabled={disabled} onClick={() => handleClick(-1)} lower>
					-
				</StepperItem>
			</StepperWrapper>
		</Wrapper>
	)
}

StepperInput.propTypes = {}

export default StepperInput
