import styled from 'styled-components'
import { Card } from 'react-bootstrap'

const StyledContainer = styled.div`
	max-width: 980px;
	margin: 0 auto;
	padding: 0 1em;
`

export default StyledContainer

export const StyledCardHeader = styled(Card.Header)`
	color: black;
	background: #d9edf7;
	padding: 1em 2em;
	font-size: 18;
`
export const ResultTable = styled.div`
	display: flex;
	justify-content: center;
`
export const ResultList = styled.div`
	color: black;
	margin-right: 1em;
`
export const ResultListItem = styled.div`
	margin-bottom: 1.2em;
`

export const Title3 = styled.h3`
	color: black;
	font-weight: bold;
`
