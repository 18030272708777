import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import TerraServiceContext from './TerraServiceContext'
import Loader from './Loader'
import moment from 'moment'
import { CardViewer } from './DoTask/CardViewer'
import FormContainer from './FormContainer'
import {
	ResultList,
	ResultListItem,
	ResultTable,
} from '../styled/StyledContainer'

const TaskResult = () => {
	const [task, setTask] = useState(null)
	const [exercise, setExercise] = useState(null)
	const { id } = useParams()
	const terraService = useContext(TerraServiceContext)

	useEffect(() => {
		terraService.getTask(id).then(res => {
			setTask(res)

			terraService.getExercise(res.exerciseId).then(res => {
				setExercise(res)
			})
		})
	}, [])

	if (task === null || exercise === null) return <Loader />

	if (task.status !== 'done') return <Redirect to="/tasks" />

	return (
		<FormContainer title="Результат выполненного задания">
			<div style={{ padding: '1em' }}>
				<ResultTable>
					<ResultList>
						<ResultListItem>Название</ResultListItem>
						<ResultListItem>Колода</ResultListItem>
						<ResultListItem>Клиент</ResultListItem>
						<ResultListItem>Дата создания</ResultListItem>
						<ResultListItem>Дата исполнения</ResultListItem>
						<ResultListItem>Описание колоды</ResultListItem>
						<ResultListItem>Инструкция для клиента</ResultListItem>
						<ResultListItem>Количество карт для выбора</ResultListItem>
					</ResultList>
					<ResultList>
						<ResultListItem>{task.name}</ResultListItem>
						<ResultListItem>{exercise.name}</ResultListItem>
						<ResultListItem>{task.client}</ResultListItem>
						<ResultListItem>
							{moment(task.created).format('DD.MM.YYYY')}{' '}
						</ResultListItem>
						<ResultListItem>
							{moment(task.completed).format('DD.MM.YYYY')}
						</ResultListItem>
						<ResultListItem>{task.description}</ResultListItem>
						<ResultListItem>{task.task}</ResultListItem>
						<ResultListItem>{exercise.cardsToPickCount}</ResultListItem>
					</ResultList>
				</ResultTable>

				<CardViewer disabled={true} cards={task.cards} />
			</div>
		</FormContainer>
	)
}

TaskResult.propTypes = {}

export { TaskResult, ResultTable, ResultList, ResultListItem }
