import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Button, FormControl, Modal } from 'react-bootstrap'

import TerraServiceContext from './TerraServiceContext'
import notify from '../utils/notify'
import Loader, { DualRing } from './Loader'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Label, BigLabel, LogInButton, StyledForm } from './LoginForm'
import style from './style/login.module.css'
import StoreContext from './StoreContext'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import ym from 'react-yandex-metrika'

const Wrapper = styled.div``

const Feedback = styled('invalid-feedback')`
	display: block;
`

const CheckBoxInput = styled.input`
	margin-left: 10px;
`
const CheckBoxLabel = styled.label`
	text-align: center;
	color: black;
`

const ConfirmModal = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	background: #eceeef;
	align-items: center;
	margin-bottom: 1em;
	min-height: 200px;
	max-height: 400px;
	overflow-x: auto;
	flex-direction: row;
	padding: 10px;
	color: black;
	justify-content: space-between;
	-webkit-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;

	h3 {
		margin-bottom: 1em;
	}
`

const confirmCard = (
	<Wrapper>
		<ConfirmModal>
			<h3>Вам было отправлено письмо с подтверждением</h3>
			<br />
			<h3>Пожалуйста, перейдите по ссылке в письме</h3>
		</ConfirmModal>
	</Wrapper>
)

const LoginSchemaPhone = Yup.object().shape({
	email: Yup.string()
		.email('Указан не верный email адрес')
		.required('Поле обязательно к заполнению'),
	phone: Yup.string()
		.min(11, 'Телефон должен быть 11 символов')
		.matches(/^\d{10,20}$/, 'Телефон должен быть 10-20 цифр')
		.required('Поле обязательно к заполнению'),
	agreePersonalDataProcessing: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо дать согласие на обработку данных',
		)
		.required('Поле обязательно к заполнению'),
	agreeTheTermsOfService: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо принять пользовательское соглашение',
		)
		.required('Поле обязательно к заполнению'),
})

const LoginSchemaEmailOnly = Yup.object().shape({
	email: Yup.string()
		.email('Указан не верный email адрес')
		.required('Поле обязательно к заполнению'),
	phone: Yup.string()
		.min(11, 'Телефон должен быть 11 символов')
		.matches(/^\d{10,20}$/, 'Телефон должен быть 10-20 цифр')
		.required('Поле обязательно к заполнению'),
	agreePersonalDataProcessing: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо дать согласие на обработку данных',
		),
	agreeTheTermsOfService: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо принять пользовательское соглашение',
		),
})

const LoginSchemaEmailPassword = Yup.object().shape({
	email: Yup.string()
		.email('Указан не верный email адрес')
		.required('Поле обязательно к заполнению'),

	phone: Yup.string()
		.min(11, 'Телефон должен быть 11 символов')
		.matches(/^\d{10,20}$/, 'Телефон должен быть 10-20 цифр')
		.required('Поле обязательно к заполнению'),

	password: Yup.string()
		.min(8, 'Пароль должен быть не менее 8 символов')
		.max(50, 'Пароль должен быть не более 50 символов')
		.required('Поле обязательно к заполнению'),

	retypePassword: Yup.string()
		.required('Введите пароль еще раз.')
		.oneOf([Yup.ref('password')], 'Ваши пароли не совпадают.'),

	agreePersonalDataProcessing: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо дать согласие на обработку данных',
		),

	agreeTheTermsOfService: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо принять пользовательское соглашение',
		),
})

const SmallLoader = styled(DualRing)`
	height: 70px;
`

const SignUpForm = () => {
	const { executeRecaptcha } = useGoogleReCaptcha()

	const [state, setState] = useState({
		awaitConfirm: false,
		loading: false,
		enterState: localStorage.getItem('enterState') ?? '0',
		phone: '',
	})

	const [phoneCode, setPhoneCode] = useState('')
	const [confirmPhoneCodeDisabled, setConfirmPhoneCodeDisabled] = useState(true)
	const searchParams = qs.parse(window.location.search, {
		ignoreQueryPrefix: true,
	})

	const [values] = useState({
		email: '',
		password: '',
		retypePassword: '',
		phone: '',
		promo: searchParams.promo ?? '',
		subscribe: true,
		agreePersonalDataProcessing: false,
		agreeTheTermsOfService: false,
		showPhoneAuthModal: false,
	})
	const terraService = useContext(TerraServiceContext)
	const store = useContext(StoreContext)

	const variants_arr = ['Email и пароль', 'Код из SMS', 'Ссылка на Email']
	const variants = variants_arr.map((answer, index) => (
		<option key={index} value={index}>
			{answer}
		</option>
	))

	const handleChangeSelect = event => {
		const ev_val = event.target.value
		localStorage.setItem('enterState', ev_val)
		setState({ ...state, enterState: ev_val })
	}

	const SwitchLoginSchema = parameter => {
		switch (parameter) {
			case '0':
				return LoginSchemaEmailPassword
			case '1':
				return LoginSchemaPhone
			case '2':
				return LoginSchemaEmailOnly
		}
	}

	const RegType = parameter => {
		switch (parameter) {
			case '0':
				return 'emailPassword'
			case '1':
				return 'sms'
			case '2':
				return 'email'
		}
	}

	const onSubmit = ({ email, password, phone, promo, subscribe }) => {
		setState({ ...state, loading: true })

		function createUser(reCaptcha) {
			terraService
				.createUser(
					email.toLowerCase(),
					phone,
					password,
					subscribe,
					reCaptcha,
					promo,
					RegType(state.enterState),
				)
				.then(
					({ user, accessToken, refreshToken }) => {
						ym('reachGoal', 'SIGNUP_FINISHED', ymParams)
						setState({ ...state, loading: false })
						switch (RegType(state.enterState)) {
							case 'emailPassword':
								localStorage.setItem('accessToken', accessToken)
								localStorage.setItem('refreshToken', refreshToken)
								store.login(user)
								notify(
									'success',
									'Аккаунт создан, пожалуйста, подтвердите свой email',
								)
								setTimeout(() => {
									setState({ ...state, loading: false, redirect: true })
								}, 3000)
								break
							case 'email':
								localStorage.setItem('accessToken', accessToken)
								localStorage.setItem('refreshToken', refreshToken)
								store.login(user)
								notify(
									'success',
									'Аккаунт создан, пожалуйста, подтвердите свой email',
								)
								setTimeout(() => {
									setState({ ...state, loading: false, redirect: true })
								}, 3000)
								break
							case 'sms':
								setState({ ...state, phone, showPhoneAuthModal: true })
								break
						}
					},
					e => {
						console.log(e.toString())
						setState({ ...state, loading: false })
						// todo сделать функцию возвращающую нормальный текст по коду ошибки
						notify('danger', 'Ошибка сервера!')
					},
				)
		}

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			createUser('test')
		} else {
			executeRecaptcha('signUp_page').then(createUser)
		}
	}

	const { loading, redirect, awaitConfirm } = state

	if (loading) return <Loader />

	if (awaitConfirm) return confirmCard

	if (redirect) return <Redirect to="/exercises" />

	let ymParams = {
		button: searchParams.button ?? 0,
		promo: searchParams.promo ?? '',
		utm_source: searchParams.utm_source ?? '',
		utm_medium: searchParams.utm_medium ?? '',
		utm_campaign: searchParams.utm_campaign ?? '',
		utm_content: searchParams.utm_content ?? '',
		utm_term: searchParams.utm_term ?? '',
	}
	// ym('reachGoal', 'SIGNUP_OPENED', ymParams)

	const ChangePhoneCodeHandler = event => {
		setPhoneCode(event.target.value)
		setConfirmPhoneCodeDisabled(event.target.value.length !== 4)
	}

	const onPhoneCodeConfirm = () => {
		setState({ ...state, loading: true })

		function phoneCodeAuth(reCaptcha) {
			terraService.phoneLogin(state.phone, phoneCode, reCaptcha, 'sms').then(
				({ user, accessToken, refreshToken }) => {
					localStorage.setItem('accessToken', accessToken)
					localStorage.setItem('refreshToken', refreshToken)
					store.login(user)
					setState({
						...state,
						loading: false,
						redirect: true,
						showPhoneAuthModal: false,
					})
				},
				e => {
					if (!e.response) {
						notify('danger', 'Пожалуйста, попробуйте чуть позже!')
					} else if (e.response.data.message === 'Please confirm email') {
						setState({ ...state, loading: false })
						notify('danger', 'Аккаунт не был подтвержден')
					} else {
						console.log(e)
						setState({ ...state, loading: false })
						if (e.response.data.message) {
							notify('danger', e.response.data.message)
						} else {
							notify('danger', 'Неправильный логин или пароль')
						}
					}
				},
			)
		}

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			phoneCodeAuth('test')
		} else {
			executeRecaptcha('login_page').then(phoneCodeAuth)
		}
	}

	return (
		<Wrapper>
			<Modal
				show={state.showPhoneAuthModal}
				onHide={() => setState({ ...state, showPhoneAuthModal: false })}
			>
				<Modal.Header closeButton>
					<Modal.Title style={{ color: 'black' }}>
						Подтвердите телефон
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ color: 'black' }}>
					{state.loading ? (
						<SmallLoader style={{ height: '70px' }} />
					) : (
						<>
							<span>
								Введите 4 последние цифры номера, от которого вы получили
								входящий вызов:
							</span>
							<input
								type="text"
								className={`form-control`}
								value={phoneCode}
								style={{ 'margin-top': '10px' }}
								onChange={ChangePhoneCodeHandler}
							/>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => setState({ ...state, showPhoneAuthModal: false })}
					>
						Отменить
					</Button>
					<Button
						variant="primary"
						disabled={confirmPhoneCodeDisabled}
						onClick={onPhoneCodeConfirm}
					>
						Подтвердить
					</Button>
				</Modal.Footer>
			</Modal>

			<Formik
				initialValues={values}
				validationSchema={SwitchLoginSchema(state.enterState)}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					handleChange,
					setFieldTouched,
				}) => {
					return (
						<StyledForm>
							<BigLabel>Регистрация</BigLabel>
							<Label>Способ входа</Label>
							<select
								className={style.select}
								onChange={handleChangeSelect}
								value={state.enterState}
							>
								{variants}
							</select>

							{(() => {
								switch (RegType(state.enterState)) {
									case 'emailPassword':
										return (
											<>
												<Label>Ваша электронная почта</Label>
												<FormControl
													value={values.email}
													name="email"
													onChange={handleChange}
													placeholder="Например, superdoc@yandex.ru"
													isInvalid={!!errors.email}
												/>
												<FormControl.Feedback type="invalid">
													{errors.email}
												</FormControl.Feedback>

												<Label>Ваш номер телефона</Label>
												<FormControl
													value={values.phone}
													name="phone"
													onChange={handleChange}
													placeholder="Например, 79101231212"
													isInvalid={!!errors.phone}
												/>
												<FormControl.Feedback type="invalid">
													{errors.phone}
												</FormControl.Feedback>

												<Label>Пароль</Label>
												<FormControl
													placeholder="от 8 символов"
													value={values.password}
													type="password"
													name="password"
													onChange={handleChange}
													isInvalid={!!errors.password}
												/>

												<Label>Повторите пароль</Label>
												<FormControl
													placeholder="от 8 символов"
													value={values.retypePassword}
													type="password"
													name="retypePassword"
													onChange={handleChange}
													isInvalid={!!errors.retypePassword}
												/>
												<FormControl.Feedback type="invalid">
													{errors.retypePassword}
												</FormControl.Feedback>
											</>
										)
									case 'sms':
										return (
											<>
												<Label>Ваша электронная почта</Label>
												<FormControl
													value={values.email}
													name="email"
													onChange={handleChange}
													placeholder="Например, superdoc@yandex.ru"
													isInvalid={!!errors.email}
												/>
												<FormControl.Feedback type="invalid">
													{errors.email}
												</FormControl.Feedback>

												<Label>Ваш номер телефона</Label>
												<FormControl
													value={values.phone}
													name="phone"
													onChange={handleChange}
													placeholder="Например, 79101231212"
													isInvalid={!!errors.phone}
												/>
												<FormControl.Feedback type="invalid">
													{errors.phone}
												</FormControl.Feedback>
											</>
										)
									case 'email':
										return (
											<>
												<Label>Ваша электронная почта</Label>
												<FormControl
													value={values.email}
													name="email"
													onChange={handleChange}
													placeholder="Например, superdoc@yandex.ru"
													isInvalid={!!errors.email}
												/>
												<FormControl.Feedback type="invalid">
													{errors.email}
												</FormControl.Feedback>

												<Label>Ваш номер телефона</Label>
												<FormControl
													value={values.phone}
													name="phone"
													onChange={handleChange}
													placeholder="Например, 79101231212"
													isInvalid={!!errors.phone}
												/>
												<FormControl.Feedback type="invalid">
													{errors.phone}
												</FormControl.Feedback>
											</>
										)
								}
							})()}

							{/* TODO validate promocode from server */}
							<Label>Промокод</Label>
							<FormControl
								value={values.promo}
								name="promo"
								onChange={handleChange}
								placeholder="Если есть. Например, ILOVETERRA"
								isInvalid={!!errors.promo}
							/>
							<FormControl.Feedback type="invalid">
								{errors.promo}
							</FormControl.Feedback>

							<CheckBoxLabel htmlFor="subscribe">
								Подписаться на новости проекта
								<CheckBoxInput
									id="subscribe"
									value={values.subscribe}
									name="subscribe"
									type="checkbox"
									checked={values.subscribe}
									onChange={handleChange}
								/>
							</CheckBoxLabel>

							<CheckBoxLabel htmlFor="agreePersonalDataProcessing">
								Я даю согласие на{' '}
								<a href="https://terra.support/privacy">
									обработку персональных данных
								</a>
								<CheckBoxInput
									id="agreePersonalDataProcessing"
									value={values.agreePersonalDataProcessing}
									name="agreePersonalDataProcessing"
									type="checkbox"
									onChange={handleChange}
								/>
								<FormControl.Feedback
									type="invalid"
									style={{
										display: errors.agreePersonalDataProcessing
											? 'block'
											: 'none',
									}}
								>
									{errors.agreePersonalDataProcessing}
								</FormControl.Feedback>
							</CheckBoxLabel>

							<CheckBoxLabel htmlFor="agreeTheTermsOfService">
								Я ознакомлен и согласен с{' '}
								<a href="https://terra.support/terms-of-use">
									условиями использования сервиса
								</a>
								<CheckBoxInput
									id="agreeTheTermsOfService"
									value={values.agreeTheTermsOfService}
									name="agreeTheTermsOfService"
									type="checkbox"
									onChange={handleChange}
								/>
								<FormControl.Feedback
									type="invalid"
									style={{
										display: errors.agreeTheTermsOfService ? 'block' : 'none',
									}}
								>
									{errors.agreeTheTermsOfService}
								</FormControl.Feedback>
							</CheckBoxLabel>

							<LogInButton type="submit">Зарегистрироваться</LogInButton>
						</StyledForm>
					)
				}}
			</Formik>
		</Wrapper>
	)
}

SignUpForm.propTypes = {}

SignUpForm.defaultProps = {}

export { ConfirmModal, SignUpForm, CheckBoxLabel, CheckBoxInput }
