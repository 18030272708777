import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from '../Header'
import { StyledContainer } from '../../styled'
import ChangePasswordForm from '../ChangePasswordForm'
import Footer from '../Footer'

const FromWrapper = styled.div`
	margin: 0 auto;
	max-width: 575px;
	margin-top: 15%;
`

const ChangePasswordPage = () => {
	return (
		<StyledContainer>
			<Header />
			<FromWrapper>
				<ChangePasswordForm />
			</FromWrapper>
			<Footer />
		</StyledContainer>
	)
}

ChangePasswordPage.propTypes = {}

ChangePasswordPage.defaultProps = {}

export default ChangePasswordPage
