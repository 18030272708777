import React from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import App from './App'
import { GridProvider } from './GridContext'

export default ({ items = [], getSortedItems, deleteItem }) => {
	return (
		<DndProvider backend={HTML5Backend}>
			<GridProvider>
				<App
					deleteItem={deleteItem}
					getSortedItems={getSortedItems}
					items={items}
				/>
			</GridProvider>
		</DndProvider>
	)
}
