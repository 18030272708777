import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledContainer } from '../../styled'
import Header from '../Header'
import ExerciseForm from '../ExerciseFrom'
import Footer from '../Footer'

const ExercisePage = () => {
	return (
		<StyledContainer>
			<Header />
			<ExerciseForm />
			<Footer />
		</StyledContainer>
	)
}

ExercisePage.propTypes = {}

export default ExercisePage
