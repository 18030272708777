import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import { copyText } from '../../utils/clipboard'
import config from '../../config'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const OperationButtonsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1.7fr 1fr;
	gap: 1em;
`

const FirstButton = styled(Button)`
	font-size: 0.9em;
`

const OperationCreateButtons = ({ taskId }) => {
	const [open, setOpen] = useState(false)

	return (
		<OperationButtonsWrapper>
			<Tooltip title="Ссылка скопирована" open={open}>
				<FirstButton
					onMouseOut={() => {
						setOpen(false)
					}}
					onClick={() => {
						copyText(`${config.baseUrl}/do-task/${taskId}`)
						setOpen(true)
					}}
				>
					Скопировать ссылку
				</FirstButton>
			</Tooltip>
			<Link to={`tasks/${taskId}`}>
				<Button variant="outline-secondary">Изменить</Button>
			</Link>
		</OperationButtonsWrapper>
	)
}

OperationCreateButtons.propTypes = {
	taskId: PropTypes.string.isRequired,
}

export default OperationCreateButtons
