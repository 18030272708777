import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import config from '../../config'
import './CardCarousel.css'

const CarouselItem = ({ level, url, cardId, onClickHandler }) => {
	const onDragStart = (event, cardId) =>
		event.dataTransfer.setData('cardId', cardId)
	const className = 'item level' + level

	return (
		<img
			onDragStart={e => onDragStart(e, cardId)}
			draggable
			onClick={() => onClickHandler(cardId, level)}
			src={config.apiUrl + url}
			className={className}
		/>
	)
}
CarouselItem.propTypes = {
	level: PropTypes.number.isRequired,
	url: PropTypes.string.isRequired,
	cardId: PropTypes.string.isRequired,
	onClickHandler: PropTypes.func.isRequired,
}

class CardCarousel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			items: this.props.cards.filter(card => card !== undefined),
			active: 0,
			direction: 'left',
		}

		this.rightClick = this.moveRight.bind(this)
		this.leftClick = this.moveLeft.bind(this)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.cards !== this.props.cards) {
			this.moveLeft()

			const items = this.props.cards.filter(card => card !== undefined)

			this.setState({
				...this.state,
				items,
			})
		}
	}

	move(index, distance, arr) {
		let len = arr.length
		distance = distance % len
		return arr[(index + distance + len) % len]
	}

	itemsPlaceHolder(active) {
		let items = this.props.cards.filter(card => card !== undefined)
		if (items.length === 6) {
			const itm = this.move(active, 3, items)
			items.push(itm)
		} else if (items.length === 5 || items.length === 3) {
			items = [...items, ...items]
		}

		return items
	}

	moveLeft() {
		if (this.state.items.length > 2) {
			let newActive = this.state.active
			newActive--

			const active = newActive < 0 ? this.state.items.length - 1 : newActive
			const items = this.itemsPlaceHolder(active)

			this.setState({
				...this.state,
				active,
				items,
				direction: 'left',
			})
		} else {
			this.setState({ ...this.state })
		}
	}

	moveRight() {
		if (this.state.items.length > 2) {
			let newActive = this.state.active
			const active = (newActive + 1) % this.state.items.length

			this.setState({
				...this.state,
				active,
				direction: 'right',
			})
		} else {
			this.setState({ ...this.state })
		}
	}

	generateItems() {
		let items = []
		let level
		let subtract = this.state.items.length >= 5 ? 2 : 1
		let add = this.state.items.length > 4 ? 3 : 2

		if (this.state.items.length === 2) {
			add = 3
		}

		if (this.props.cards.filter(card => card !== undefined).length === 3) {
			subtract = 1
			add = 2
		}

		for (
			let i = this.state.active - subtract;
			i < this.state.active + add;
			i++
		) {
			let index = i
			let onClickHandler = (cardId, level) => {
				if (level === 0 || this.state.items.length <= 2) {
					this.props.onSelect(cardId)
				} else if (level === -1) {
					this.rightClick()
				} else if (level === 1) {
					this.leftClick()
				} else if (level === 2) {
					this.leftClick()
					setTimeout(this.leftClick, 250)
				} else if (level === -2) {
					this.rightClick()
					setTimeout(this.rightClick, 250)
				}
			}

			if (i < 0) {
				index = this.state.items.length + i
			} else if (i >= this.state.items.length) {
				index = i % this.state.items.length
			}

			if (this.state.items.length === 1) {
				level = 0
			} else if (this.state.items.length <= 3) {
				level = this.state.active - i === -2 ? 1 : this.state.active - i
			} else {
				level = this.state.active - i
			}

			if (this.state.items[index]) {
				items.push(
					<CarouselItem
						key={index}
						onClickHandler={onClickHandler}
						url={this.state.items[index].url}
						cardId={this.state.items[index]._id}
						level={level}
					/>,
				)
			}
		}
		let preLoadView = []
		let afterLoadView = []

		if (items.length > 0) {
			for (let i = 1; i <= 3; i++) {
				const preView = this.move(Number(items[0].key), -i, this.state.items)
				const afterView = this.move(
					Number(items[items.length - 1].key),
					i,
					this.state.items,
				)

				preLoadView.push(
					<CarouselItem
						key={preView._id}
						onClickHandler={() => {}}
						url={preView.url}
						cardId={preView._id}
						level={'-hidden'}
					/>,
				)

				afterLoadView.push(
					<CarouselItem
						key={afterView._id}
						onClickHandler={() => {}}
						url={afterView.url}
						cardId={afterView._id}
						level={'-hidden'}
					/>,
				)
			}

			return [...items, ...preLoadView, ...afterLoadView]
		}
		return items
	}

	render() {
		return (
			<div>
				<div className="arrow arrow-left" onClick={this.rightClick}></div>
				<CSSTransitionGroup
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300}
					transitionName={this.state.direction}
				>
					{this.generateItems()}
				</CSSTransitionGroup>
				<div className="arrow arrow-right" onClick={this.leftClick}></div>
			</div>
		)
	}
}

export default CardCarousel
