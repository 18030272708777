import React, { useContext, useEffect, useState, useCallback } from 'react'
import TerraServiceContext from '../TerraServiceContext'
import PropTypes from 'prop-types'
import Loader from '../Loader'
import styled from 'styled-components'
import { Button, Card } from 'react-bootstrap'
import notify from '../../utils/notify'
import { CardViewer, SelectedCards, SelectedCardsWrapper } from './CardViewer'
import CardCarousel from './CardCarousel'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { DraggableCard } from './DraggableCard'

const CarouselCard = styled(Card)`
	width: 100%;
	min-height: 350px;
	overflow: hidden;
`

const Wrapper = styled.div`
	margin: 1em 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
`

const ButtonWrapper = styled.div`
	height: fit-content;
	margin: 1em;
`

const ConfirmButton = styled(Button)`
	margin-left: 0.5em;
`
const RandomButton = styled(Button)`
	margin-right: 0.5em;
`

let debounceTimer

const Carousel = props => {
	const {
		onCompleteHandler,
		autoSaveHandler,
		selectedCards,
		exerciseId,
		cardsAmount,
		status,
	} = props

	const [exercise, setExercise] = useState(null)

	const [carouselCards, setCarouselCards] = useState({
		selected: [],
		available: [],
	})

	const [errors, setErrors] = useState([])
	const terraService = useContext(TerraServiceContext)

	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = carouselCards.selected[dragIndex]
			const cards = update(carouselCards.selected, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard],
				],
			})
			setCarouselCards({ ...carouselCards, selected: cards })
		},
		[carouselCards.selected],
	)

	useEffect(() => {
		terraService.getExercise(exerciseId).then(res => {
			const sci = selectedCards.map(c => c._id)

			res.cards = res.cards.sort(
				(a, b) => sci.indexOf(a._id) - sci.indexOf(b._id),
			)

			const [selected, available] = res.cards.partition(card => {
				let isSelected = false
				selectedCards.forEach(c => {
					if (c._id === card._id) {
						card.description = c.description
						isSelected = true
					}
				})
				return isSelected
			})

			setCarouselCards({ ...carouselCards, selected, available })

			setExercise(res)
		})
	}, [])

	if (exercise === null)
		return (
			<Card>
				<Loader />
			</Card>
		)

	if (status === 'done') {
		return <CardViewer disabled={true} cards={selectedCards} />
	} else {
		const selectCard = cardId => {
			if (cardId) {
				if (cardsAmount > carouselCards.selected.length) {
					const [
						[selectedCard],
						newAvailable,
					] = carouselCards.available.partition(card => card._id === cardId)
					setCarouselCards({
						...carouselCards,
						available: newAvailable,
						selected: [...carouselCards.selected, selectedCard],
					})

					autoSaveHandler([...carouselCards.selected, selectedCard], null)
				} else {
					notify(
						'danger',
						`Вы не можете выбрать больше ${cardsAmount} карточек`,
					)
				}
			} else {
				autoSaveHandler(carouselCards.selected, null)
			}
		}

		const removeSelectedCardHandler = card => {
			delete card.description
			delete card.isSelected

			const [[selectedCard], newSelected] = carouselCards.selected.partition(
				c => c._id === card._id,
			)
			setCarouselCards({
				...carouselCards,
				available: [...carouselCards.available, selectedCard],
				selected: newSelected,
			})

			autoSaveHandler(newSelected, null)
		}

		const cardsUpdateHandler = (card, text) => {
			clearTimeout(debounceTimer)

			card.description = text
			selectedCards.forEach(c => {
				if (c._id === card._id) {
					c.description = card.description
					setErrors([...errors.filter(id => id !== card.id)])
				}
			})

			debounceTimer = setTimeout(
				() => autoSaveHandler(carouselCards.selected, null),
				1000,
			)

			setCarouselCards({ ...carouselCards })
		}

		const selectRandomCard = () => {
			const randomCard =
				carouselCards.available[
					Math.floor(Math.random() * carouselCards.available.length)
				]
			selectCard(randomCard._id)
		}

		const completeExercise = () => {
			let canComplete = true

			const emptyCards = []

			carouselCards.selected.forEach(card => {
				if (!card.description || card.description === '') {
					canComplete = false
					emptyCards.push(card._id)
				}
			})

			if (canComplete) {
				if (cardsAmount > carouselCards.selected.length) {
					notify(
						'danger',
						`Вы выбрали только ${carouselCards.selected.length} из ${cardsAmount} карточек`,
					)
				} else {
					clearTimeout(debounceTimer)
					onCompleteHandler(carouselCards.selected, null)
				}
			} else {
				notify('danger', `Комментарий для карточки не может быть пустым`)
				setErrors([...errors, ...emptyCards])
			}
		}

		const draggableCards = carouselCards.selected.map((card, index) => {
			return (
				<DraggableCard
					index={index}
					card={card}
					moveCard={moveCard}
					id={card._id}
					key={card._id}
					error={errors.includes(card._id)}
					cardsUpdateHandler={cardsUpdateHandler}
					removeSelectedCardHandler={removeSelectedCardHandler}
				/>
			)
		})

		return (
			<Wrapper>
				<CarouselCard>
					<Card.Body>
						<CardCarousel
							onSelect={selectCard}
							cards={carouselCards.available}
						/>
					</Card.Body>
				</CarouselCard>

				<SelectedCards>
					<SelectedCardsWrapper
						onDragOver={ev => ev.preventDefault()}
						onDrop={event => {
							selectCard(event.dataTransfer.getData('cardId'))
						}}
					>
						<DndProvider backend={Backend}>{draggableCards}</DndProvider>
					</SelectedCardsWrapper>
					<ButtonWrapper>
						<RandomButton variant="secondary" onClick={selectRandomCard}>
							Случайная карта
						</RandomButton>
						<ConfirmButton onClick={completeExercise}>Закончить</ConfirmButton>
					</ButtonWrapper>
				</SelectedCards>
			</Wrapper>
		)
	}
}

Carousel.propTypes = {
	exerciseId: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	cardsAmount: PropTypes.number.isRequired,
	onCompleteHandler: PropTypes.func.isRequired,
	autoSaveHandler: PropTypes.func.isRequired,
	selectedCards: PropTypes.array.isRequired,
}

export default Carousel
