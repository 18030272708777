import React, { useContext, useEffect, useState } from 'react'
import TerraServiceContext from '../TerraServiceContext'
import MainTable from '../Table'
import OperationCreateButtons from './OperationCreateButtons'
import NavigationCreateButtons from './NavigationCreateButtons'
import config from '../../config'
import StoreContext from '../StoreContext'
let wss

const TaskTable = ({ filter }) => {
	const [tasks, setTasks] = useState([])
	const [exercises, setExercises] = useState([])
	const [state, setState] = useState({
		pageAmount: 0,
		offset: 0,
	})

	const {
		state: { user },
		showLoading,
		hideLoading,
	} = useContext(StoreContext)

	const { pageAmount, offset } = state

	const terraService = useContext(TerraServiceContext)
	useEffect(() => {
		fetchTasks(offset)
	}, [offset])

	useEffect(() => {
		fetchTasks()
	}, [filter])

	useEffect(() => {
		wss = new WebSocket(config.wssUrl + `?userId=${user.id}`)
	}, [])

	if (wss) {
		wss.onmessage = evt => {
			const message = JSON.parse(evt.data)
			switch (message.type) {
				case 'status':
					statusUpdate(message.exerciseId, message.status)
					break
				case 'exercise':
					fetchTasks(offset)
					break
			}
		}
	}

	async function fetchTasks(offset = 0) {
		showLoading()
		terraService
			.getExercises()
			.then(({ exercises, pageAmount }) => {
				setExercises(exercises)
				terraService
					.getTasks(
						{
							limit: 10,
							offset,
						},
						filter,
					)
					.then(({ tasks, pageAmount }) => {
						setTasks(tasks)
						setState({ ...state, pageAmount })
						hideLoading()
					})
					.catch(e => {
						console.log(e)
						hideLoading()
					})
			})
			.catch(e => {
				console.log(e)
				hideLoading()
			})
	}

	const columns = [
		{
			id: 'client',
			label: 'Клиент',
		},
		{
			id: 'name',
			label: 'Название',
		},
		{
			id: 'exercise_name',
			label: 'Колода',
		},
		{
			id: 'operation',
			label: 'Операции',
			width: '34%',
		},
	]

	function createData(tasks, exercises) {
		return tasks.map(task => {
			let operation = ''

			if (task.status === 'created') {
				operation = <OperationCreateButtons taskId={task.id} />
			}

			if (task.status === 'done') {
				operation = <NavigationCreateButtons taskId={task.id} />
			}
			const exercise = exercises.find(e => e.id === task.exerciseId)
			let exercise_name = ''
			if (exercise) {
				exercise_name = exercise.name
			}
			return {
				client: task.client,
				name: task.name,
				exercise_name: exercise_name,
				status: task.status,
				operation,
			}
		})
	}

	const rows = createData(tasks, exercises)

	const statusUpdate = (id, status) => {
		let canUpdate = false

		const updatedTasks = tasks.map(task => {
			if (task.id === id) {
				task.status = status
				canUpdate = true
			}
			return task
		})

		if (canUpdate) setTasks(updatedTasks)
	}

	const handlePagination = offset => {
		setState({ ...state, offset })
	}

	return (
		<MainTable
			rows={rows}
			columns={columns}
			offset={offset}
			pageAmount={pageAmount}
			handlePagination={handlePagination}
		/>
	)
}

TaskTable.propTypes = {}

export default TaskTable
