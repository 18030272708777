import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import HasPermission from './utils/HasPermission'

const OperationButtonsWrapper = styled.div`
	display: flex;
	flex-direction: inherit;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
`

const FirstButton = styled(Button)`
	font-size: 0.9em;
`

const OperationButtons = ({
	id,
	editLink,
	exercise,
	actionTitle,
	handleFirst,
	preview,
	hasChangePermission,
	hasActionPermission,
}) => (
	<OperationButtonsWrapper>
		<Button onClick={() => preview(id)} variant="outline-secondary">
			Посмотреть
		</Button>
		<Link disabled={!hasChangePermission} to={`${editLink}/${id}`}>
			<Button disabled={!hasChangePermission} variant="outline-secondary">
				Изменить
			</Button>
		</Link>
		{exercise ? (
			<Link
				disabled={!hasActionPermission}
				to={{
					pathname: '/task',
					item: {
						exerciseId: id,
					},
				}}
			>
				<Button disabled={!hasActionPermission}>{actionTitle}</Button>
			</Link>
		) : (
			<FirstButton disabled={!hasActionPermission} onClick={handleFirst}>
				{actionTitle}
			</FirstButton>
		)}
	</OperationButtonsWrapper>
)

OperationButtons.propTypes = {
	id: PropTypes.string.isRequired,
	editLink: PropTypes.string.isRequired,
}

export default OperationButtons
