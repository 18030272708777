import React, { useContext, useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormControl } from 'react-bootstrap'

import TerraServiceContext from './TerraServiceContext'
import notify from '../utils/notify'
import Loader from './Loader'
import { ConfirmModal } from './SignUpForm'
import { InvalidCaptcha, Label, LogInButton, StyledForm } from './LoginForm'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Wrapper = styled.div``

const BackLink = styled(Link)`
	margin-top: 1em;
`

const RequestSchema = Yup.object().shape({
	email: Yup.string()
		.email('Указан не верный email адрес')
		.required('Поле обязательно к заполнению'),
})

const PasswordSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, 'Пароль должен быть не менее 8 символов')
		.max(50, 'Пароль должен быть не более 24 символов')
		.required('Поле обязательно к заполнению'),
})

const ChangePasswordForm = () => {
	const { token } = useParams()

	const { executeRecaptcha } = useGoogleReCaptcha()

	const [state, setState] = useState({
		loading: false,
		needConfirm: false,
	})

	const [requestValues] = useState({
		email: '',
	})

	const [changeValues] = useState({
		password: '',
	})

	const terraService = useContext(TerraServiceContext)

	const onRequestSubmit = ({ email }) => {
		setState({ ...state, loading: true })

		function requestChangePassword(reCaptcha) {
			terraService.requestChangePassword(email.toLowerCase(), reCaptcha).then(
				() => {
					notify('success', 'Email для восстановления пароля был отправлен')
					setState({ ...state, loading: false, needConfirm: true })
				},
				e => {
					console.log(e)
					setState({ ...state, loading: false, needConfirm: false })
					notify('danger', 'Ошибка')
				},
			)
		}
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			requestChangePassword('test')
		} else {
			executeRecaptcha('changePassword_page').then(requestChangePassword)
		}
	}

	const onPasswordSubmit = ({ password }) => {
		setState({ ...state, loading: true })

		function submitChangedPassword(reCaptcha) {
			terraService.submitChangedPassword(token, password, reCaptcha).then(
				() => {
					notify('success', 'Пароль был успешно изменен')
					setState({ ...state, loading: false, needConfirm: true })
				},
				e => {
					console.log(e)
					setState({ ...state, loading: false, needConfirm: false })
					notify('danger', 'Ошибка')
				},
			)
		}

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			submitChangedPassword('test')
		} else {
			executeRecaptcha('changePassword_page').then(submitChangedPassword)
		}
	}

	const { loading, needConfirm } = state

	if (loading) return <Loader />

	if (needConfirm) {
		if (token) {
			return (
				<Wrapper>
					<ConfirmModal>
						<h3>Вы успешно изменили пароль</h3>
						<BackLink to={`/login`}>
							<LogInButton>На главную</LogInButton>
						</BackLink>
					</ConfirmModal>
				</Wrapper>
			)
		} else {
			return (
				<Wrapper>
					<ConfirmModal>
						<h3>Вам было отправлено письмо подтверждением</h3>
						<h3>Пожалуйста перейдите по ссылке в письме</h3>
					</ConfirmModal>
				</Wrapper>
			)
		}
	}

	const requestForm = (
		<Wrapper>
			<Formik
				initialValues={requestValues}
				validationSchema={RequestSchema}
				onSubmit={onRequestSubmit}
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					handleChange,
					setFieldTouched,
				}) => {
					return (
						<StyledForm>
							<Label>Ваша электронная почта</Label>
							<FormControl
								value={values.email}
								name="email"
								onChange={handleChange}
								placeholder="Например, superdoc@yandex.ru"
								isInvalid={!!errors.email}
							/>
							<FormControl.Feedback type="invalid">
								{errors.email}
							</FormControl.Feedback>

							<div style={{ margin: '0 auto' }}>
								<LogInButton type="submit">Восстановить</LogInButton>
							</div>
						</StyledForm>
					)
				}}
			</Formik>
		</Wrapper>
	)

	const changeForm = (
		<Wrapper>
			<Formik
				initialValues={changeValues}
				validationSchema={PasswordSchema}
				onSubmit={onPasswordSubmit}
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					handleChange,
					setFieldTouched,
				}) => {
					return (
						<StyledForm>
							<Label>Пароль</Label>
							<FormControl
								placeholder="от 8 символов"
								value={values.password}
								type="password"
								name="password"
								onChange={handleChange}
								isInvalid={!!errors.password}
							/>
							<FormControl.Feedback type="invalid">
								{errors.password}
							</FormControl.Feedback>

							<div style={{ margin: '0 auto' }}>
								<LogInButton type="submit">Установить</LogInButton>
							</div>
						</StyledForm>
					)
				}}
			</Formik>
		</Wrapper>
	)

	return token ? changeForm : requestForm
}

ChangePasswordForm.propTypes = {}

ChangePasswordForm.defaultProps = {}

export default ChangePasswordForm
