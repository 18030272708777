import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Push = styled.div``

const Top = styled.div``

const Wrapper = styled.div`
	height: 40px;
	display: flex;
	align-items: end;
	padding: 0 1em;
	border-radius: 5px;
	margin-top: 1em;
	max-width: 600px;
	margin: 0 auto;

	a,
	a:hover {
		text-decoration: none;
	}
`

const Item = styled(`a`)`
	display: block;
	font-weight: lighter;
	font-size: 0.9em;
	cursor: pointer;
	margin-top: 8px;
	margin-bottom: 0.85em;
`

const Footer = () => {
	return (
		<Top>
			<Push />
			<Wrapper>
				<Item href="https://terra.support/privacy/">
					Политика конфиденциальности
				</Item>
				<Item href="https://terra.support/terms-of-use/">
					・Пользовательское соглашение
				</Item>
				<Item href="https://terra.support/contacts/">・Контакты</Item>
			</Wrapper>
		</Top>
	)
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
