import React, { useContext, useEffect } from 'react'
import DragItem from './DragItem'
import { Grid, GridCoverImage, GridItem, DeleteIcon } from './Grid'
import GridContext from './GridContext'
import ImagePreview from '../utils/ImagePreview'

function App({ items, getSortedItems, deleteItem }) {
	const { setItems, moveItem, items: sortedItems } = useContext(GridContext)

	useEffect(() => {
		setItems(items)
		getSortedItems(sortedItems.length === 0 ? items : sortedItems)
	}, [items])

	return (
		<div className="App">
			<Grid>
				{items.map(item => (
					<DragItem key={item.id} id={item.id} onMoveItem={moveItem}>
						<GridItem>
							<DeleteIcon
								onClick={e => {
									deleteItem(item.id)
									e.stopPropagation()
								}}
							/>
							<ImagePreview
								onClick={e => e.preventDefault()}
								name={item.key}
								src={item.src}
							>
								<GridCoverImage src={item.src} />
							</ImagePreview>
						</GridItem>
					</DragItem>
				))}
			</Grid>
		</div>
	)
}

export default App
