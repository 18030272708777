import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const NavigationButtonsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1.7fr 1fr;
	gap: 1em;
`

const FirstButton = styled(Button)`
	font-size: 0.9em;
`

const NavigationCreateButtons = ({ taskId }) => {
	return (
		<NavigationButtonsWrapper>
			<Link to={`result/${taskId}`}>
				<FirstButton>Результаты</FirstButton>
			</Link>
		</NavigationButtonsWrapper>
	)
}

NavigationCreateButtons.propTypes = {
	taskId: PropTypes.string.isRequired,
}

export default NavigationCreateButtons
