import React from 'react';

const NotFoundPage = () => {
	return (
		<>
			<h1 style={{color :'#000'}}>404 Page not found</h1>
		</>
	);
}

export default NotFoundPage;
