import React from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import { StyledContainer } from '../../styled'
import { TaskResult } from '../TaskResult'
import Footer from '../Footer'

const ResultPage = () => {
	return (
		<StyledContainer>
			<Header />
			<TaskResult />
			<Footer />
		</StyledContainer>
	)
}

ResultPage.propTypes = {}

export default ResultPage
