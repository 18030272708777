import React from 'react'
import Header from '../Header'
import { StyledContainer } from '../../styled'
import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import { StyledCardHeader } from '../../styled/StyledContainer'

const StyledPageTitle = styled.h1`
	color: black;
	text-align: center;
	font-size: 24px;
	margin: 1em;
`

const Paragraph = styled.p`
	color: black;
	text-indent: 15px;
	margin-top: 10px;
`

const StyledIframe = styled.iframe`
	display: block;
	margin: 2em auto;
`
const HelpPage = () => {
	return (
		<StyledContainer>
			<Header />
			<Card style={{ width: '100%', marginTop: '1em' }}>
				<StyledCardHeader>Справка</StyledCardHeader>
				<Card.Body style={{ padding: '2em' }}>
					<StyledPageTitle>О сервисе</StyledPageTitle>
					<Paragraph>
						Terra - это сервис для онлайн работы с метафорическими
						ассоциативными картами.
					</Paragraph>
					<Paragraph>
						Метафорические ассоциативные карты (МАК) - это колода картинок с
						разными изображениями, персонажами или образами. Нам не всегда легко
						формулировать свои переживания словами и тогда на помощь приходят
						визуальные образы. Эти карты называются метафорическими
						ассоциативными, потому что каждый рисунок — это ассоциация или
						метафора мыслей, чувств, воспоминаний или переживаний. С помощью
						колоды МАК можно работать с разными проблемами и запросами клиента.
						Чаще всего при работе с МАК клиенту дают колоду и просят выбрать
						карты по какому-то определенному принципу (например "какие больше
						всего похожи на него самого" или "на его состояние сейчас" и т.д.).
					</Paragraph>
					<Paragraph>
						На нашей платформе вы сможете создавать любые задания для себя и
						ваших клиентов из более чем 10 базовых колод или собрать свою
						собственную уникальную колоду.
					</Paragraph>
					<Paragraph>
						<StyledPageTitle>
							Как создать и использовать задание?
						</StyledPageTitle>
						1. Выберите колоду, которая больше всего подходит для ваших целей.
						Чтобы ознакомиться с картами каждой колоды, можете нажать
						«Посмотреть».
						<br />
						2. У понравившейся колоды нажмите на «Создать задание» слева.
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-1.png"
						/>
						<br />
						3. В появившемся окне введите имя вашего клиента, инструкцию, какие
						карты и по какому принципу он может выбирать, далее укажите сколько
						всего карт он должен выбрать.
						<br />
						4. Нажмите «Создать» и тогда созданное вами задание появится в вашем
						списке заданий.
						<br />
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-2.png"
						/>
						<br />
						5. Нажмите «Скопировать ссылку» и отправьте ее своему клиенту, чтобы
						он мог выполнить задание, либо самостоятельно, либо вместе с вами на
						вашей онлайн сессии.
						<br />
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-3.png"
						/>
						<br />
						6. После прохождения клиентом задания нажмите кнопку "Результаты"
						чтобы обсудить выбор клиента с ним.
					</Paragraph>
					<Paragraph>
						<StyledPageTitle>Как выполнять задание?</StyledPageTitle>
						1. При переходе по ссылке клиент попадет на экран с колодой и вашим
						заданием.
						<br />
						2. Карты можно пролистывать по одной. Для выбора карты можно на неё
						нажать или перетащить в область снизу.
						<br />
						3. Выбрав карту клиент должен оставить поясняющий комментарий.
						<br />
						4. Когда задание будет выполнено, то клиенту нужно нажать
						"Закончить". После этого результат отобразится в вашем списке
						заданий.
						<br />
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-4.png"
						/>
						<br />
					</Paragraph>
					<Paragraph>
						<StyledPageTitle>Как загрузить свою колоду?</StyledPageTitle>
						1. Нажмите «Загрузить свою колоду» во вкладке «Колоды».
						<br />
						2. Придумайте название для своей колоды в поле «Название колоды».
						<br />
						3. Расскажите из каких карт она состоит в поле «Описание колоды».
						<br />
						4. Выберите нужные изображение в поле «Выбрать файлы».
						<br />
						5. Нажмите «Создать» и тогда созданная вами колода появится в вашем
						списке колод. Тут вы можете их изменить или посмотреть карты уже
						созданных колод.
						<br />
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-5.png"
						/>
						<br />
					</Paragraph>

					<Paragraph>
						<StyledPageTitle>Как сменить тариф?</StyledPageTitle>
						1. Перейдите во вкладку «Мой профиль».
						<br />
						2. Внизу выберите подходящий вам тариф и нажмите «Сменить».
						<br />
						3. Внимательно ознакомьтесь с условиями списания абонентской платы и
						отмены тарифа. Если вы согласны, то нажмите «Подтвердить»
						<br />
						4. Введите данные вашей карты для оплаты подписки.
						<br />
						5. Если всё пройдет успешно, то платежный терминал перекинет вас
						обратно во вкладку «Мой профиль», где вы можете увидеть ваш активный
						тариф.
						<br />
						5. Спасибо, что пользуетесь нашим сервисов и оплачиваете услуги, это
						очень важно для нас.
						<br />
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-6.png"
						/>
						<br />
					</Paragraph>

					<Paragraph>
						<StyledPageTitle>Как отменить подписку?</StyledPageTitle>
						1. Перейдите во вкладку «Мой профиль».
						<br />
						2. Внизу выберите тариф "Бесплатный" и нажмите «Сменить».
						<br />
						3. Внимательно ознакомьтесь с условиями отмены тарифа. Если вы
						согласны, то нажмите «Подтвердить»
						<br />
						4. Теперь вы на бесплатном тарифе. Подписка была отменена.
						<br />
						<img
							style={{ margin: '1em auto', display: 'block', width: '100%' }}
							src="/images/help-7.png"
						/>
						<br />
					</Paragraph>
				</Card.Body>
			</Card>
		</StyledContainer>
	)
}

HelpPage.propTypes = {}

HelpPage.defaultProps = {}

export default HelpPage
