import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TaskForm from '../TaskForm'
import Header from '../Header'
import { StyledContainer } from '../../styled'
import Footer from '../Footer'

const TaskPage = () => {
	return (
		<StyledContainer>
			<Header />
			<TaskForm />
			<Footer />
		</StyledContainer>
	)
}

TaskPage.propTypes = {}

export default TaskPage
