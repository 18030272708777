import React, { useContext } from 'react'
import { StyledContainer } from '../../styled'
import Header from '../Header'
import SecondaryHeader from '../SecondaryHeader'
import ExerciseTable from '../ExerciseTable'
import StoreContext from '../StoreContext'
import Footer from '../Footer'

const ExerciseListPage = () => {
	const {
		state: { user },
	} = useContext(StoreContext)

	return (
		<StyledContainer>
			<Header />
			<SecondaryHeader
				isFilter={false}
				title="Колоды"
				createLink="/exercise"
				createTitle="Загрузить свою колоду"
				createDisabled={user.tariff === 'free' || user.tariff === 'advanced'}
			/>
			<ExerciseTable />
			<Footer />
		</StyledContainer>
	)
}

ExerciseListPage.propTypes = {}

export default ExerciseListPage
