import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from '../Header'
import { StyledContainer } from '../../styled'
import { SignUpForm } from '../SignUpForm'
import Footer from '../Footer'

const FromWrapper = styled.div`
	margin: 0 auto;
	max-width: 575px;
	margin-top: 15%;
	color: red;
`

const SignUpPage = () => {
	return (
		<StyledContainer>
			<Header />
			<FromWrapper>
				<SignUpForm />
			</FromWrapper>
			<Footer />
		</StyledContainer>
	)
}

SignUpPage.propTypes = {}

SignUpPage.defaultProps = {}

export default SignUpPage
