import React, { useReducer } from 'react'
import StoreContext from './StoreContext'
import { reducer, types, initialState } from '../reducer'

const Store = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const login = user => {
		dispatch({ type: types.LOGIN, user })
	}

	const logout = () => {
		dispatch({ type: types.LOGOUT })
	}

	const showLoading = () => {
		dispatch({ type: types.SHOW_LOADING })
	}

	const hideLoading = () => {
		dispatch({ type: types.HIDE_LOADING })
	}

	const initial = {
		state,
		login,
		logout,
		hideLoading,
		showLoading,
	}

	return (
		<StoreContext.Provider value={initial}>{children}</StoreContext.Provider>
	)
}

export default Store
