import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
	background-color:#332851;
`

const ForbiddenPage = () => {
	return (
		<Wrapper>
			<div className="base io">
				<h1 className="io">403</h1>
				<h2>Access forbidden</h2>
				<h5>(I'm sorry buddy...)</h5>
			</div>
		</Wrapper>
	)
}

ForbiddenPage.propTypes = {}

ForbiddenPage.defaultProps = {}

export default ForbiddenPage