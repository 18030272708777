import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Header from '../Header'
import StyledContainer from '../../styled/StyledContainer'
import SecondaryHeader from '../SecondaryHeader'
import TaskTable from '../TaskTable/TaskTable'
import StoreContext from '../StoreContext'
import notify from '../../utils/notify'
import Footer from '../Footer'

const TasksListPage = () => {
	const {
		state: { user },
	} = useContext(StoreContext)

	const [filter, setFilter] = useState({
		created: true,
		inProgress: true,
		done: true,
	})

	const { created, inProgress, done } = filter

	const [firstRun, setFirstRun] = useState(true)
	if (firstRun && !user.confirmed) {
		notify(
			'danger',
			'Вы не сможете создать задание пока не подтвердите электронную почту',
		)
		setFirstRun(false)
	}

	useEffect(() => {
		if (!created && !inProgress && !done) {
			setFilter({
				created: true,
				inProgress: true,
				done: true,
			})
		}
	}, [filter])

	return (
		<StyledContainer>
			<Header />
			<SecondaryHeader
				toggleFilter={name => () =>
					setFilter({ ...filter, [name]: !filter[name] })}
				filter={filter}
				title="Задания"
				createLink="/task"
				createTitle="Создать задание"
				createDisabled={!user.confirmed}
			/>

			<TaskTable filter={filter} />

			<Footer />
		</StyledContainer>
	)
}

TasksListPage.propTypes = {}

TasksListPage.defaultProps = {}

export default TasksListPage
