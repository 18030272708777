import React, { useContext, useState, useEffect } from 'react'
import { Redirect, useParams, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormControl, Button, Modal } from 'react-bootstrap'

import TerraServiceContext from './TerraServiceContext'
import notify from '../utils/notify'
import Loader, { DualRing } from './Loader'
import StoreContext from './StoreContext'
import { ConfirmModal } from './SignUpForm'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import style from './style/login.module.css'
import ym from 'react-yandex-metrika'

const Wrapper = styled.div``

const Label = styled.p`
	color: #000;
`

const BigLabel = styled.p`
	color: #000;
	font-weight: bold;
	font-size: large;
	text-align: center;
	margin: 5px;
`

const StyledForm = styled(Form)`
	background-color: #eceeef;
	padding: 1em;
	border-radius: 5px;
	display: grid;
	grid-gap: 0.7em;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.275) !important;
`

const LogInButton = styled(Button)`
	justify-self: center;
	margin: 0.3em 1em;
`

const InvalidCaptcha = styled.p`
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
`

const TextLink = styled.p`
	color: #53c1fb;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`

const SmallLoader = styled(DualRing)`
	height: 70px;
`

const LoginSchemaEmailPassword = Yup.object().shape({
	email: Yup.string()
		.email('Указан не верный email адрес')
		.required('Поле обязательно к заполнению'),

	password: Yup.string()
		.min(8, 'Пароль должен быть не менее 8 символов')
		.max(50, 'Пароль должен быть не более 50 символов')
		.required('Поле обязательно к заполнению'),
})

const LoginSchemaPhone = Yup.object().shape({
	phone: Yup.string()
		.min(11, 'Телефон должен быть не менее 11 символов')
		.matches(/^\d{10,20}$/, 'Телефон должен быть 10-20 цифр')
		.required('Поле обязательно к заполнению'),
})

const LoginSchemaEmailOnly = Yup.object().shape({
	email: Yup.string()
		.email('Указан не верный email адрес')
		.required('Поле обязательно к заполнению'),
})

const LoginForm = () => {
	const { token } = useParams()

	const { executeRecaptcha } = useGoogleReCaptcha()

	const [state, setState] = useState({
		redirect: false,
		loading: false,
		// todo why use 0, 1, 2?
		enterState: localStorage.getItem('enterState') ?? '0',
		// todo
		phoneAuth: '',
		showPhoneAuthModal: false,
	})

	const [values] = useState({
		email: '',
		password: '',
		phone: '',
	})

	const [confirmPhoneCodeDisabled, setConfirmPhoneCodeDisabled] = useState(true)
	const [phoneCode, setPhoneCode] = useState('')

	const onPhoneCodeConfirm = () => {
		setState({ ...state, loading: true })

		function phoneCodeAuth(reCaptcha) {
			terraService
				.phoneLogin(state.phoneAuth, phoneCode, reCaptcha, 'sms')
				.then(
					({ user, accessToken, refreshToken }) => {
						localStorage.setItem('accessToken', accessToken)
						localStorage.setItem('refreshToken', refreshToken)
						ym('reachGoal', 'LOGIN_PHONE_FINISHED')
						store.login(user)
						setState({
							...state,
							loading: false,
							redirect: true,
							showPhoneAuthModal: false,
						})
					},
					e => {
						if (!e.response) {
							notify('danger', 'Пожалуйста, попробуйте чуть позже!')
						} else if (e.response.data.message === 'Please confirm email') {
							setState({ ...state, loading: false })
							notify('danger', 'Аккаунт не был подтвержден')
						} else {
							console.log(e)
							setState({ ...state, loading: false })
							if (e.response.data.message) {
								notify('danger', e.response.data.message)
							} else {
								notify('danger', 'Неправильный логин или пароль')
							}
						}
					},
				)
		}

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			phoneCodeAuth('test')
		} else {
			executeRecaptcha('login_page').then(phoneCodeAuth)
		}
	}

	const store = useContext(StoreContext)
	const terraService = useContext(TerraServiceContext)

	// todo use from signUp
	const RegType = parameter => {
		switch (parameter) {
			case '0':
				return 'emailPassword'
			case '1':
				return 'sms'
			case '2':
				return 'email'
		}
	}

	const onSubmit = props => {
		const { password, phone = 'null' } = props
		const email = props.email.toLowerCase()

		async function auth(reCaptcha) {
			switch (RegType(state.enterState)) {
				case 'email':
					terraService.requestAuth({ email }, reCaptcha).then(
						() => {
							notify(
								'success',
								'Перейдите по ссылке, отправленной на вашу электронную почту',
							)
						},
						e => {
							// todo нормальные ошибки
							// todo не зарегистрирован?
							if (!e.response) {
								notify('danger', 'Пожалуйста, попробуйте чуть позже!')
							} else if (e.response.data.message === 'Please confirm email') {
								setState({ ...state, loading: false, email })
								notify('danger', 'Аккаунт не был подтвержден')
							} else {
								console.log(e)
								setState({ ...state, loading: false })
								if (e.response.data.message) {
									notify('danger', e.response.data.message)
								} else {
									// todo никогда так не писать
									notify('danger', 'Что-то пошло не так')
								}
							}
						},
					)
					break
				case 'sms':
					terraService.requestAuth({ phone }, reCaptcha).then(
						() => {
							// start modal dialog
							setState({ ...state, phoneAuth: phone, showPhoneAuthModal: true })
						},
						e => {
							// todo нормальные ошибки
							// todo не зарегистрирован?
							// todo timeout
							if (!e.response) {
								notify('danger', 'Пожалуйста, попробуйте чуть позже!')
							} else if (e.response.data.message === 'Please confirm email') {
								setState({ ...state, loading: false, email })
								notify('danger', 'Аккаунт не был подтвержден')
							} else {
								console.log(e)
								setState({ ...state, loading: false })
								// todo никогда так не писать
								notify('danger', 'Что-то пошло не так :<')
							}
						},
					)
					break
				case 'emailPassword':
					setState({ ...state, loading: true })
					terraService
						.emailPasswordLogin(
							email,
							password,
							reCaptcha,
							RegType(state.enterState),
						)
						.then(
							({ user, accessToken, refreshToken }) => {
								localStorage.setItem('accessToken', accessToken)
								localStorage.setItem('refreshToken', refreshToken)
								ym('reachGoal', 'LOGIN_EMAIL_PASSWORD_FINISHED')
								store.login(user)
								setState({ ...state, loading: false, redirect: true })
							},
							e => {
								if (!e.response) {
									notify('danger', 'Пожалуйста, попробуйте чуть позже!')
								} else if (e.response.data.message === 'Please confirm email') {
									setState({
										...state,
										loading: false,
										email,
									})
									notify('danger', 'Аккаунт не был подтвержден')
								} else {
									console.log(e)
									setState({ ...state, loading: false })
									notify('danger', 'Неправильный логин или пароль')
								}
							},
						)
					break
			}
		}

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			auth('test')
		} else {
			executeRecaptcha('login_page').then(auth)
		}
	}

	useEffect(() => {
		;(() => {
			if (token) {
				setState({ ...state, loading: true })

				terraService.confirmUser(token).then(
					response => {
						if (response) {
							if (response.msg && response.msg === 'confirmed') {
								notify('success', 'Аккаунт был подтвержден!')
							}
							localStorage.setItem('accessToken', response.tokens.accessToken)
							localStorage.setItem('refreshToken', response.tokens.refreshToken)
							ym('reachGoal', 'LOGIN_EMAIL_FINISHED')
							store.login(response.user)
							setState({ ...state, loading: false, redirect: true })
						} else {
							setState({ ...state, loading: false })
						}
					},
					error => {
						console.log(error)
						setState({ ...state, loading: false })
						notify('danger', 'Ошибка')
					},
				)
			}
		})()
	}, [])

	const { loading, redirect } = state

	if (loading) return <Loader />

	if (redirect) return <Redirect to="/exercises" />

	// ym('reachGoal', 'LOGIN_OPENED')

	const variants_arr = ['Email и пароль', 'Код из SMS', 'Ссылка на Email']
	const variants = variants_arr.map((answer, index) => (
		<option key={index} value={index}>
			{answer}
		</option>
	))

	const handleChangeSelect = event => {
		const ev_val = event.target.value
		localStorage.setItem('enterState', ev_val)
		setState({ ...state, enterState: ev_val })
	}

	// todo save default login method in browser!!!
	const SwitchLoginSchema = parameter => {
		switch (parameter) {
			case '0':
				return LoginSchemaEmailPassword
				break
			case '1':
				return LoginSchemaPhone
				break
			case '2':
				return LoginSchemaEmailOnly
				break
		}
	}

	// todo reuse all this shit
	const ChangePhoneCodeHandler = event => {
		setPhoneCode(event.target.value)
		setConfirmPhoneCodeDisabled(event.target.value.length !== 4)
	}

	return (
		<Wrapper>
			<Modal
				show={state.showPhoneAuthModal}
				onHide={() => setState({ ...state, showPhoneAuthModal: false })}
			>
				<Modal.Header closeButton>
					<Modal.Title style={{ color: 'black' }}>
						Подтвердите телефон
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ color: 'black' }}>
					{state.loading ? (
						<SmallLoader style={{ height: '70px' }} />
					) : (
						<>
							<span>
								Введите 4 последние цифры номера, от которого вы получили
								входящий вызов:
							</span>
							<input
								type="text"
								className={`form-control`}
								style={{ 'margin-top': '10px' }}
								value={phoneCode}
								onChange={ChangePhoneCodeHandler}
							/>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => setState({ ...state, showPhoneAuthModal: false })}
					>
						Отменить
					</Button>
					<Button
						variant="primary"
						disabled={confirmPhoneCodeDisabled}
						onClick={onPhoneCodeConfirm}
					>
						Подтвердить
					</Button>
				</Modal.Footer>
			</Modal>

			<Formik
				initialValues={values}
				validationSchema={SwitchLoginSchema(state.enterState)}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					handleChange,
					setFieldTouched,
				}) => {
					return (
						<StyledForm>
							<BigLabel>Вход</BigLabel>
							<Label>Способ входа</Label>
							<select
								className={style.select}
								onChange={handleChangeSelect}
								value={state.enterState}
							>
								{variants}
							</select>

							{(() => {
								switch (RegType(state.enterState)) {
									case 'sms':
										return (
											<>
												<Label>Ваш номер телефона</Label>
												<FormControl
													value={values.phone}
													name="phone"
													onChange={handleChange}
													placeholder="Например, 79101112233"
													isInvalid={!!errors.phone}
												/>
												<FormControl.Feedback type="invalid">
													{errors.phone}
												</FormControl.Feedback>
											</>
										)
									case 'email':
										return (
											<>
												<Label>Ваша электронная почта</Label>
												<FormControl
													value={values.email}
													name="email"
													onChange={handleChange}
													placeholder="Например, superdoc@yandex.ru"
													isInvalid={!!errors.email}
												/>
												<FormControl.Feedback type="invalid">
													{errors.email}
												</FormControl.Feedback>
											</>
										)
									case 'emailPassword':
										return (
											<>
												<Label>Ваша электронная почта</Label>
												<FormControl
													value={values.email}
													name="email"
													onChange={handleChange}
													placeholder="Например, superdoc@yandex.ru"
													isInvalid={!!errors.email}
												/>
												<FormControl.Feedback type="invalid">
													{errors.email}
												</FormControl.Feedback>

												<Label>Пароль</Label>
												<FormControl
													placeholder="от 8 символов"
													value={values.password}
													type="password"
													name="password"
													onChange={handleChange}
													isInvalid={!!errors.password}
												/>
											</>
										)
								}
							})()}

							<FormControl.Feedback type="invalid">
								{errors.password}
							</FormControl.Feedback>

							<div style={{ margin: '0 auto' }}>
								<LogInButton type="submit">Войти</LogInButton>
								<Link to={`/change-password`}>
									<LogInButton variant="outline-secondary">
										Забыл пароль
									</LogInButton>
								</Link>
							</div>
						</StyledForm>
					)
				}}
			</Formik>
		</Wrapper>
	)
}

LoginForm.propTypes = {}

LoginForm.defaultProps = {}

export { LoginForm, InvalidCaptcha, LogInButton, StyledForm, Label, BigLabel }
