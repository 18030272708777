import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 1em;
	align-items: center;
	${({ isFilter }) =>
		isFilter &&
		css`
			@media screen and (max-width: 630px) {
				align-items: center;
				flex-direction: column;
			}
		`}
`

const Title = styled.h2`
	color: #000;
	font-size: 1.5em;
	${({ isFilter }) =>
		isFilter &&
		css`
			@media screen and (max-width: 630px) {
				margin-bottom: 1em;
			}
		`}
`

const Filter = styled(ButtonGroup)`
	margin-right: 1em;

	@media screen and (max-width: 490px) {
		margin-bottom: 1em;
	}
`

const FilterItem = styled.button`
	padding: 0.6em;
	border: 1px solid #eceeef;
	opacity: 0.7;
		font-weight: normal;
	outline: none !important;

	${({ backgroundColor }) =>
		backgroundColor &&
		css`
			background-color: ${backgroundColor};
		`};

	${({ color }) =>
		color &&
		css`
			color: ${color};
		`}

	${({ radius }) =>
		radius &&
		css`
		border-bottom-${radius}-radius: 5px;
		border-top-${radius}-radius: 5px;
	`}
	
	${({ active }) =>
		active &&
		css`
			opacity: 1;
			font-weight: 600;
		`}
`

const Div = styled.div`
	@media screen and (max-width: 490px) {
		flex-direction: column;
		display: flex;
		align-items: center;
	}
`

const SecondaryHeader = ({
	title,
	toggleFilter = () => {},
	isFilter = true,
	createLink,
	createTitle,
	createDisabled = false,
	filter,
}) => {
	return (
		<Wrapper isFilter={isFilter}>
			<Title isFilter={isFilter}>{title}</Title>
			<Div>
				{isFilter && (
					<Filter>
						<FilterItem
							onClick={toggleFilter('done')}
							active={filter.done}
							radius="left"
							color="#3D763E"
							backgroundColor="#DFF0D8"
						>
							Выполненные
						</FilterItem>
						<FilterItem
							active={filter.inProgress}
							onClick={toggleFilter('inProgress')}
							color="#31708F"
							backgroundColor="#FCF8E3"
						>
							Запущенные
						</FilterItem>
						<FilterItem
							onClick={toggleFilter('created')}
							active={filter.created}
							radius="right"
							color="#8A6D3B"
							backgroundColor="#FFF"
						>
							Созданные
						</FilterItem>
					</Filter>
				)}
				<Link disabled={createDisabled} to={createLink}>
					<Button disabled={createDisabled}>{createTitle}</Button>
				</Link>
			</Div>
		</Wrapper>
	)
}

SecondaryHeader.propTypes = {
	title: PropTypes.string.isRequired,
	createLink: PropTypes.string.isRequired,
	createDisabled: PropTypes.bool,
	toggleFilter: PropTypes.func,
	isFilter: PropTypes.bool,
}

export default SecondaryHeader
