import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useParams, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'
import TerraServiceContext from './TerraServiceContext'
import { TextField, MenuItem, Select, Tooltip } from '@material-ui/core'
import StepperInput from './StepperInput'
import notify from '../utils/notify'
import FormContainer from './FormContainer'
import StoreContext from './StoreContext'
import ExercisePreviewModal from './utils/ExercisePreviewModal'
import ym from 'react-yandex-metrika'

const taskSchema = Yup.object().shape({
	client: Yup.string().required('Поле обязательно к заполнению'),
	exerciseId: Yup.string().required('Поле обязательно к заполнению'),
	name: Yup.string().required('Поле обязательно к заполнению'),
	description: Yup.string().required('Поле обязательно к заполнению'),
	task: Yup.string().required('Поле обязательно к заполнению'),
})

const maxInputWidth = '250px'

const FormWrapper = styled.div`
	gap: 1em;
	display: grid;
	padding: 1em;
`

const FormItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const StyledTextField = styled(TextField)`
	width: 100vh;
	max-width: ${maxInputWidth};
`

const StyledSelect = styled(Select)`
	width: 100vh;
	max-width: ${maxInputWidth};
	height: 2.5em !important;
`

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
	max-width: 640px;
	margin: 0 auto;
`

const SubmitButton = styled(Button)`
	align-self: center;
	padding: 0.5em 2em;
	margin: 1em;
`

const Label = styled.p`
	@media screen and (max-width: 440px) {
		margin-bottom: 1em;
	}
`

const StyledStepperInput = styled(StepperInput)`
	width: 100vh;
	max-width: 220px;
`

const FormItemButton = styled(Button)`
	margin-left: 1em;
`

const TaskForm = props => {
	const terraService = useContext(TerraServiceContext)
	const store = useContext(StoreContext)

	const [state, setState] = useState({
		exercises: [],
		redirect: false,
		isOpenPreview: false,
	})

	const [values, setValues] = useState({
		client: '',
		exerciseId: '',
		name: '',
		description: '',
		task: '',
		cardsAmount: 1,
	})

	const { id: taskId } = useParams()

	useEffect(() => {
		;(async () => {
			try {
				store.showLoading()
				const { exercises } = await terraService.getExercises()
				const exerciseId =
					props.location.item && props.location.item.exerciseId
						? props.location.item.exerciseId
						: null

				if (exerciseId) {
					const exercise = exercises.find(e => e.id === exerciseId)

					setState(prevState => {
						return {
							...prevState,
							maxCardAmount: exercise.cards.length,
						}
					})

					setValues({
						...values,
						exerciseId: exerciseId,
						description: exercise.description,
						task: '',
						cardsAmount: 1,
					})
				}

				if (taskId) {
					const task = await terraService.getTask(taskId)
					setValues(task)

					const { exerciseId } = task
					const exercise = exercises.find(e => e.id === exerciseId)
					setState(prevState => {
						return {
							...prevState,
							maxCardAmount: exercise.cards.length,
							exercises,
						}
					})
				} else {
					setState(prevState => {
						return { ...prevState, exercises }
					})
				}
			} catch (e) {
				console.log(e)
			}

			store.hideLoading()
		})()
	}, [])

	const handleSubmit = values => async (method, successMsg, errorMsg) => {
		try {
			store.showLoading()
			await terraService[method](values, taskId)
			store.hideLoading()
			setState({ ...state, redirect: true })
			ym('reachGoal', 'TASK_CREATED')
			notify('success', successMsg)
		} catch (e) {
			if (e.response.data === 'No task available') {
				notify('danger', 'У вас больше не осталось заданий на этот месяц!')
			} else {
				notify('danger', errorMsg)
			}
			store.hideLoading()
		}
	}

	const onSubmit = async values => {
		const submit = handleSubmit(normalizeTask(values))
		if (taskId) {
			submit(
				'updateTask',
				'Задание обновлено успешно',
				'Не удалось изменить задание',
			)
		} else {
			submit(
				'createTask',
				'Задание создано успешно',
				'Не удалось создать задание',
			)
		}
	}

	const normalizeTask = task => ({
		client: task.client,
		exerciseId: task.exerciseId,
		name: task.name,
		description: task.description,
		task: task.task,
		cardsAmount: task.cardsAmount,
	})

	const { exercises, maxCardAmount, redirect, isOpenPreview } = state

	if (redirect) return <Redirect to="/tasks" />

	// ym('reachGoal', 'TASK_FORM_OPENED')

	return (
		<FormContainer title="Создание задания">
			<ExercisePreviewModal
				isOpen={isOpenPreview}
				exercise={exercises.find(e => e.id === values.exerciseId)}
				closeModal={() => setState({ ...state, isOpenPreview: false })}
			/>
			<Formik
				initialValues={values}
				validationSchema={taskSchema}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					handleChange,
					setFieldTouched,
					setFieldValue,
				}) => {
					const onChange = name => e => {
						e.persist()

						if (name === 'exerciseId') {
							const id = e.target.value
							const exercise = exercises.find(e => e.id === id)

							setState({
								...state,
								maxCardAmount: exercise.cards.length,
							})

							setValues({
								...values,
								name: exercise.name,
								exerciseId: e.target.value,
								description: exercise.description,
								task: '',
								cardsAmount: 1,
							})

							setFieldValue('cardsAmount', 1)
							setFieldTouched('cardsAmount', true, false)
						}

						handleChange(e)
						setFieldTouched(name, true, false)
					}
					const handleCardsAmountChange = val => {
						setFieldValue('cardsAmount', val)
						setFieldTouched('cardsAmount', true, false)
					}

					return (
						<StyledForm>
							<div>
								<FormWrapper>
									<FormItem>
										<Label>Клиент</Label>
										<div>
											<StyledTextField
												placeholder="Например: Екатерина"
												size="small"
												name="client"
												variant="outlined"
												helperText={touched.client ? errors.client : ''}
												error={touched.client && Boolean(errors.client)}
												onChange={onChange('client')}
												value={values.client}
											/>
											<FormItemButton style={{ visibility: 'hidden' }}>
												Посмотреть
											</FormItemButton>
										</div>
									</FormItem>

									<FormItem>
										<Label>Колода</Label>
										<div className="no-focus-form-item">
											<StyledSelect
												InputLabelProps={{
													FormLabelClasses: {
														focused: 'no-focus',
													},
												}}
												variant="outlined"
												size="small"
												name="exerciseId"
												helperText={touched.exerciseId ? errors.exerciseId : ''}
												error={touched.exerciseId && Boolean(errors.exerciseId)}
												value={values.exerciseId}
												onChange={onChange('exerciseId')}
											>
												{exercises.map(e => (
													<MenuItem key={e.id} value={e.id}>
														{e.name}
													</MenuItem>
												))}
											</StyledSelect>
											<FormItemButton
												disabled={values.exerciseId === ''}
												onClick={() =>
													setState({ ...state, isOpenPreview: true })
												}
												variant="outline-primary"
											>
												Посмотреть
											</FormItemButton>
										</div>
									</FormItem>

									<FormItem>
										<Label>Название</Label>
										<div>
											<StyledTextField
												placeholder="Например: Упражнение о предназначении"
												size="small"
												name="name"
												variant="outlined"
												helperText={touched.name ? errors.name : ''}
												error={touched.name && Boolean(errors.name)}
												onChange={onChange('name')}
												value={values.name}
											/>
											<FormItemButton style={{ visibility: 'hidden' }}>
												Посмотреть
											</FormItemButton>
										</div>
									</FormItem>

									<FormItem>
										<Label>Описание колоды</Label>
										<div>
											<StyledTextField
												multiline
												rowsMax="4"
												size="small"
												name="description"
												variant="outlined"
												helperText={
													touched.description ? errors.description : ''
												}
												error={
													touched.description && Boolean(errors.description)
												}
												onChange={onChange('description')}
												value={values.description}
											/>
											<FormItemButton style={{ visibility: 'hidden' }}>
												Посмотреть
											</FormItemButton>
										</div>
									</FormItem>

									<FormItem>
										<Label>Инструкция для клиента</Label>
										<div>
											<StyledTextField
												multiline
												rowsMax="4"
												size="small"
												name="task"
												variant="outlined"
												helperText={touched.task ? errors.task : ''}
												error={touched.task && Boolean(errors.task)}
												onChange={onChange('task')}
												value={values.task}
											/>
											<FormItemButton style={{ visibility: 'hidden' }}>
												Посмотреть
											</FormItemButton>
										</div>
									</FormItem>

									<FormItem>
										<Label>Количество карт для выбора</Label>
										<Tooltip
											disableFocusListener={Boolean(values.exerciseId)}
											disableTouchListener={Boolean(values.exerciseId)}
											disableHoverListener={Boolean(values.exerciseId)}
											title="Сначала выберете колоду"
										>
											<span>
												<StyledStepperInput
													disabled={!values.exerciseId}
													size="small"
													name="cardsAmount"
													variant="outlined"
													cardsLimit={maxCardAmount}
													helperText={
														touched.cardsAmount ? errors.cardsAmount : ''
													}
													error={
														touched.cardsAmount && Boolean(errors.cardsAmount)
													}
													handleChange={handleCardsAmountChange}
													value={values.cardsAmount}
												/>
											</span>
										</Tooltip>
										<FormItemButton style={{ visibility: 'hidden' }}>
											Посмотреть
										</FormItemButton>
									</FormItem>
								</FormWrapper>
							</div>

							<div style={{ margin: '0 auto' }}>
								<SubmitButton variant="primary" type="submit">
									{taskId ? 'Изменить' : 'Создать'}
								</SubmitButton>

								<SubmitButton
									variant="outline-secondary"
									onClick={props.history.goBack}
								>
									Отменить
								</SubmitButton>
							</div>
						</StyledForm>
					)
				}}
			</Formik>
		</FormContainer>
	)
}

TaskForm.propTypes = {}

export default withRouter(TaskForm)
