import React from 'react'
import DocumentMeta from 'react-document-meta'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Store from '../components/Store'
import routes from '../routes'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import '../assets/normalize.css'
import '../assets/reset.css'
import '../assets/global.css'

import NotFoundPage from './pages/NotFoundPage'
import PrivateRoute from './PrivateRoute'
import TerraServiceContext from './TerraServiceContext'
import TinkoffServiceContext from './TinkoffServiceContext'
import TerraService from '../services/terra-servise'
import LoadingComponent from './utils/LoadingComponent'
import { YMInitializer } from 'react-yandex-metrika'
import TinkoffService from '../services/tinkoff-servise'

const terraService = new TerraService()
const tinkoffService = new TinkoffService()

const meta = {
	meta: {
		name: {
			viewport: 'initial-scale=0.5',
		},
	},
}

const App = () => {
	return (
		<>
			<YMInitializer accounts={[65200492]} />
			<DocumentMeta {...meta} />
			<Store>
				<TerraServiceContext.Provider value={terraService}>
					<TinkoffServiceContext.Provider value={tinkoffService}>
						<BrowserRouter>
							<LoadingComponent />
							<Switch>
								{routes.map(route => (
									<PrivateRoute
										key={route.path}
										meta={route.meta}
										path={route.path}
										exact
										Component={route.component}
									/>
								))}
								<Route component={NotFoundPage} />
							</Switch>
						</BrowserRouter>
					</TinkoffServiceContext.Provider>
				</TerraServiceContext.Provider>
			</Store>
			<ReactNotification />
		</>
	)
}

export default App
