import React from 'react'
import styled from 'styled-components'

export const DualRing = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	height: 48vh;
	align-items: center;

	&:after {
		content: ' ';
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid red;
		border-color: red transparent red transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}

	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

const Loader = () => {
	return <DualRing />
}

export default Loader
