import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
	border: 1px solid #eceeef;
	color: #000;
	margin: 1em 0 0 0;

	@media screen and (max-width: 440px) {
		border: none;
	}
`

const Header = styled.div`
	border-bottom: 1px solid #eceeef;
	background: #fafbfd;
	padding: 1em;

	@media screen and (max-width: 440px) {
		display: none;
	}
`

const FormLabel = styled.h3`
	font-size: 1.2em;
`

const FormContainer = ({ children, title }) => {
	return (
		<Wrapper>
			<Header>
				<FormLabel>{title}</FormLabel>
			</Header>
			{children}
		</Wrapper>
	)
}

FormContainer.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
}

export default FormContainer
