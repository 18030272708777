import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DoTask from '../DoTask/DoTask'

const DoTaskPage = () => {
	return <DoTask />
}

DoTaskPage.propTypes = {}

export default DoTaskPage
