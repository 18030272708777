import React, { useContext, useState } from 'react'
import { Button, Card, FormControl, Modal } from 'react-bootstrap'
import {
	ResultList,
	ResultListItem,
	ResultTable,
	StyledCardHeader,
	Title3,
} from '../styled/StyledContainer'
import TerraServiceContext from './TerraServiceContext'
import StoreContext from './StoreContext'
import * as Yup from 'yup'
import styled from 'styled-components'
import Loader, { DualRing } from './Loader'
import { Form, Formik } from 'formik'
import notify from '../utils/notify'
import config from '../config'
import moment from 'moment'
import style from './style/profile.module.css'
import TinkoffServiceContext from './TinkoffServiceContext'
import { CheckBoxInput, CheckBoxLabel } from './SignUpForm'
import ym from 'react-yandex-metrika'

const StyledTable = styled(ResultTable)`
	max-width: 670px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
`
const StyledTariffContainer = styled.div`
	display: flex;
	justify-content: space-between;
	color: #373a3c;
	max-width: 770px;
	margin: 0 auto;
`

const TariffCard = styled(Card)`
	width: 200px;
	background: #eceeef;
`

const TariffBody = styled(Card.Body)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const TariffButton = styled(Button)`
	display: block;
	margin: 1em auto;
`

const TariffParagraph = styled.p`
	margin-bottom: 1em;
	text-align: center;
	font-size: 14px;
	color: black;
`

const P = styled.p`
	margin: 1em;
	color: black;
	text-indent: 15px;
`
const TariffTitle = styled(Card.Title)`
	text-align: center;
	font-size: 1.2rem;
`
const SmallLoader = styled(DualRing)`
	height: 70px;
`
const updatePasswordSchema = Yup.object().shape({
	newPassword: Yup.string()
		.min(8, 'Пароль должен быть не менее 8 символов')
		.required('Поле обязательно к заполнению'),
	oldPassword: Yup.string(),
})

const FormWrapper = styled.div`
	gap: 1em;
	display: grid;
`

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
	max-width: 670px;
	margin: 0 auto;
`

const FormItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Label = styled.p`
	@media screen and (max-width: 440px) {
		margin-bottom: 1em;
	}
	color: black;
`

const SubmitButton = styled(Button)`
	align-self: center;
	padding: 0.5em 2em;
	margin: 1em;
`

const StyledTextField = styled(FormControl)`
	width: 100vh;
	max-width: 340px;
`

const ConfirmModal = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	background: #eceeef;
	align-items: center;
	margin-bottom: 1em;
	min-height: 200px;
	max-height: 400px;
	overflow-x: auto;
	flex-direction: row;
	padding: 10px;
	color: black;
	justify-content: space-between;
	-webkit-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;

	h3 {
		margin-bottom: 1em;
	}
`
const Wrapper = styled.div``

const PaymentConfirmSchema = Yup.object().shape({
	agreePersonalDataProcessing: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо дать согласие на обработку данных',
		)
		.required('Поле обязательно к заполнению'),
	agreeTheTermsOfService: Yup.boolean()
		.required('Поле обязательно к заполнению')
		.oneOf(
			[true],
			'Для продолжения необходимо принять пользовательское соглашение',
		)
		.required('Поле обязательно к заполнению'),
})

const Profile = () => {
	const terraService = useContext(TerraServiceContext)
	const tinkoffService = useContext(TinkoffServiceContext)

	const {
		state: { user },
	} = useContext(StoreContext)

	const [changeEmailButtonDisabled, setChangeEmailButtonDisabled] = useState(
		true,
	)
	const [changePhoneButtonDisabled, setChangePhoneButtonDisabled] = useState(
		true,
	)
	const [userEmail, setUserEmail] = useState(user.email)
	const [userPhone, setUserPhone] = useState(user.phone)
	const [userTariff, setUserTariff] = useState(user.tariff)
	const [userAvailableTasks, setUserAvailableTasks] = useState(
		user.availableTasks,
	)

	const tariffTranslationAndAmount = (tariff, showSpecialPrice = false) => {
		let tariffTranslation, tariffAmount
		switch (tariff) {
			case 'free':
				tariffTranslation = 'Бесплатный'
				tariffAmount = 0
				break
			case 'advanced':
				tariffTranslation = 'Расширенный'
				tariffAmount = 249
				break
			case 'specialist':
				tariffTranslation = 'Специалист'
				tariffAmount = 399
				break
			case 'pro':
				tariffTranslation = 'Профи'
				tariffAmount = 999
				break
			default:
				throw { error: 'wrong tariff' }
		}
		if ('specialPrice' in user && showSpecialPrice) {
			tariffAmount = user.specialPrice
		}
		if ('specialDiscount' in user && !showSpecialPrice) {
			tariffAmount = Math.round(tariffAmount * (1 - user.specialDiscount))
		}
		if (
			(user.roles.includes('admin') || user.roles.includes('free')) &&
			showSpecialPrice
		) {
			tariffAmount = 0
		}
		return { tariffTranslation, tariffAmount }
	}

	// todo кейс установки почты, когда ее не было - отправить ссылку на почту. типа не подтверждай, если не хочешь
	// todo кейс установки телефона, когда его не было - отправить код на телефон
	// todo что если меняют на имеющийся номер или почту?!
	let { tariffTranslation, tariffAmount } = tariffTranslationAndAmount(
		user.tariff,
		true,
	)
	const [state, setState] = useState({
		showConfirmTariffChangeModal: false,
		showTempTariffLockedChangeModal: false,
		loading: false,
		loadingPassword: false,
		tariffTranslation,
		tariffAmount,
	})
	const [stateUser, _] = useState(user)

	const [values, setValues] = useState({
		newPassword: '',
		oldPassword: '',
	})

	const [paymentConfirmValues] = useState({
		agreePersonalDataProcessing: false,
		agreeTheTermsOfService: false,
	})

	const confirmTariffHandler = async tariff => {
		let { tariffTranslation, tariffAmount } = tariffTranslationAndAmount(tariff)

		setState({
			...state,
			tariff,
			tariffTranslation,
			tariffAmount,
			showConfirmTariffChangeModal: true,
		})
	}

	const showTempTariffLockedChangeHandler = async tariff => {
		let tariffTranslation

		setState({
			...state,
			tariff,
			tariffTranslation,
			showTempTariffLockedChangeModal: true,
		})
	}

	const onConfirmTariff = async () => {
		setState({ ...state, showConfirmTariffChangeModal: false })

		try {
			if (state.tariff !== 'free') {
				const formData = await terraService.createPayment(state.tariff)

				tinkoffService.init(formData).then(
					res => {
						if (res.Success) {
							ym('reachGoal', 'PAYMENT_REDIRECT', {
								tariff: state.tariff,
								tariffAmount: state.tariffAmount,
							})
							window.location.replace(res.PaymentURL)
						} else {
							console.error(res.Details)
						}
					},
					err => {
						console.error(err)
					},
				)
			} else {
				ym('reachGoal', 'SWITCH_TO_FREE')
				const res = await terraService.updateTariff(state.tariff)

				setUserTariff(res.tariff)
				setUserAvailableTasks(res.availableTasks)

				notify('success', 'Тариф успешно изменен!')
			}
		} catch (e) {
			notify('danger', 'Ошибка обновления тарифа!')
			console.error(e)
		}
	}

	const onSubmit = async values => {
		if (values.newPassword === values.oldPassword) {
			notify('danger', 'Новый пароль не может быть таким же, как и старый!')
		} else {
			setState({ ...state, loadingPassword: true })
			try {
				const res = await terraService.updatePassword(values)

				setValues({
					newPassword: '',
					oldPassword: '',
				})

				setState({ ...state, loadingPassword: false })
				notify('success', 'Пароль был успешно обновлен!')
			} catch (e) {
				setState({ ...state, loadingPassword: false })
				notify('danger', 'Ошибка обновления пароля!')
				console.log(e)
			}
		}
	}

	if (state.loadingPassword) return <Loader />

	const ChangeEmailHandler = event => {
		setUserEmail(event.target.value)

		if (user.email == event.target.value) {
			setChangeEmailButtonDisabled(prevNum => true)
		} else {
			setChangeEmailButtonDisabled(prevNum => false)
		}
	}
	const ChangePhoneHandler = event => {
		setUserPhone(event.target.value)

		if (user.phone == event.target.value) {
			setChangePhoneButtonDisabled(prevNum => true)
		} else {
			setChangePhoneButtonDisabled(prevNum => false)
		}
	}

	const onConfirmEmail = async () => {
		setState({ ...state, loading: true })
		terraService.sendConfirmEmail(userEmail).then(
			() => {
				notify('success', 'Новый email для подтверждения был отправлен')
				setState({ ...state, loading: false, needConfirm: false })
			},
			e => {
				console.log(e)
				setState({ ...state, loading: false, needConfirm: false })
				notify('danger', 'Ошибка')
			},
		)
	}

	const onSubmitChangeEmail = async () => {
		setState({ ...state, loading: true })

		try {
			const res = await terraService.updateEmail(userEmail)

			notify('success', 'Новый email был установлен!')
			user.email = userEmail

			setChangeEmailButtonDisabled(true)
			setUserEmail(prev => userEmail)
		} catch (e) {
			notify('danger', 'Ошибка обновления email!')
			console.error(e)
		}
		setState({ ...state, loading: false })
	}

	const onSubmitChangePhone = async () => {
		setState({ ...state, loading: true })
		try {
			const res = await terraService.updatePhone(userPhone)

			setUserPhone(prev => userPhone)
			user.phone = userPhone
			setChangePhoneButtonDisabled(true)
			notify('success', 'Новый телефон был установлен!')
		} catch (e) {
			notify('danger', 'Ошибка обновления телефона!')
			console.error(e)
		}
		setState({ ...state, loading: false })
	}

	const { loading } = state

	if (loading) return <Loader />

	// ym('reachGoal', 'PROFILE_OPENED')

	return (
		<Card style={{ width: '100%', marginTop: '1em' }}>
			<StyledCardHeader>Настройки профиля</StyledCardHeader>

			<Modal
				show={state.showConfirmTariffChangeModal}
				onHide={() =>
					setState({ ...state, showConfirmTariffChangeModal: false })
				}
			>
				<Modal.Header closeButton>
					<Modal.Title style={{ color: 'black' }}>Вы уверены?</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ color: 'black' }}>
					{state.loading ? (
						<SmallLoader style={{ height: '70px' }} />
					) : (
						<span>
							Подтвердите, что хотите перейти на тариф "
							{state.tariffTranslation}"
							{state.tariffAmount > 0 ? (
								<ul style={{ padding: '10px' }}>
									<li>
										Ежемесячная плата - {state.tariffAmount} рублей в месяц
									</li>
									<li>
										Первый платеж сегодня, далее - через 1 календарный месяц
									</li>
									<li>Вы получите оповещение за один день до списания денег</li>
									<li>
										Для отмены подписки нажмите на кнопку "Отменить подписку" в
										профиле
									</li>
								</ul>
							) : (
								<ul style={{ padding: '10px' }}>
									<li>Вы потеряете возможность создавать свои колоды</li>
									<li>Вам станут доступны только 2 базовые колоды</li>
									<li>
										Вы сможете создавать только 2 задания для клиентов в месяц
									</li>
									<li>Вы нас расстроите :(</li>
								</ul>
							)}
						</span>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Formik
						initialValues={paymentConfirmValues}
						validationSchema={PaymentConfirmSchema}
						onSubmit={onConfirmTariff}
					>
						{({
							values,
							errors,
							touched,
							isSubmitting,
							handleChange,
							setFieldTouched,
						}) => {
							return (
								<Form>
									<div>
										<CheckBoxInput
											id="agreePersonalDataProcessing"
											value={values.agreePersonalDataProcessing}
											name="agreePersonalDataProcessing"
											type="checkbox"
											onChange={handleChange}
										/>{' '}
										<CheckBoxLabel
											htmlFor="agreePersonalDataProcessing"
											style={{ display: 'contents' }}
										>
											Я даю согласие на{' '}
											<a href="https://terra.support/privacy">
												обработку персональных данных
											</a>
											<div>
												<FormControl.Feedback
													type="invalid"
													style={{
														display: errors.agreePersonalDataProcessing
															? 'block'
															: 'none',
													}}
												>
													{errors.agreePersonalDataProcessing}
												</FormControl.Feedback>
											</div>
										</CheckBoxLabel>
										<CheckBoxLabel
											htmlFor="agreeTheTermsOfService"
											style={{ paddingTop: '0.5em', display: 'contents' }}
										>
											<CheckBoxInput
												id="agreeTheTermsOfService"
												value={values.agreeTheTermsOfService}
												name="agreeTheTermsOfService"
												type="checkbox"
												onChange={handleChange}
											/>{' '}
											Я согласен с тарифом, его суммой и правилами списания
											<div>
												<FormControl.Feedback
													type="invalid"
													style={{
														display: errors.agreeTheTermsOfService
															? 'block'
															: 'none',
													}}
												>
													{errors.agreeTheTermsOfService}
												</FormControl.Feedback>
											</div>
										</CheckBoxLabel>
									</div>

									<div
										style={{
											justifyContent: 'flex-end',
											display: 'flex',
											paddingTop: '0.5em',
										}}
									>
										<Button
											style={{ margin: '.25rem' }}
											variant="secondary"
											onClick={() =>
												setState({
													...state,
													showConfirmTariffChangeModal: false,
												})
											}
										>
											Отменить
										</Button>
										<Button
											style={{ margin: '.25rem' }}
											variant="primary"
											type="submit"
										>
											Подтвердить
										</Button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</Modal.Footer>
			</Modal>

			<Modal
				show={state.showTempTariffLockedChangeModal}
				onHide={() =>
					setState({ ...state, showTempTariffLockedChangeModal: false })
				}
			>
				<Modal.Header closeButton>
					<Modal.Title style={{ color: 'black' }}>
						Смена тарифа временно недоступна
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ color: 'black' }}>
					{state.loading ? (
						<SmallLoader style={{ height: '70px' }} />
					) : (
						<span>Извините, эта опция скоро снова станет доступна</span>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() =>
							setState({ ...state, showTempTariffLockedChangeModal: false })
						}
					>
						Отменить
					</Button>
				</Modal.Footer>
			</Modal>

			{/*Formik?*/}
			<Card.Body style={{ padding: '3em 1em' }}>
				<StyledTable>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>Ваш email</ResultListItem>
					</ResultList>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>
							<div className={style.flexdiv}>
								<input
									type="text"
									className={`form-control`}
									value={userEmail}
									onChange={ChangeEmailHandler}
								/>
								<button
									type="submit"
									className={`btn btn-primary ${style.flexbutton}`}
									disabled={changeEmailButtonDisabled}
									onClick={onSubmitChangeEmail}
								>
									Изменить
								</button>
								{!user.confirmed && (
									<button
										type="submit"
										className={`btn btn-warning ${style.flexbutton}`}
										style={{ color: '#fff' }}
										onClick={onConfirmEmail}
									>
										Подтвердить
									</button>
								)}
							</div>
							{stateUser.newemail && !stateUser.newemail.empty && (
								<>
									<div className={style.flexdivTop}>
										<p>
											Чтобы сменить почту, перейдите по ссылке, отправленной на
											эту почту: <b>{user.email}</b>
										</p>
									</div>
									{/*отменить смену почты?*/}
								</>
							)}
						</ResultListItem>
					</ResultList>
				</StyledTable>

				{/*Formik?*/}
				<StyledTable>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>Номер телефона</ResultListItem>
					</ResultList>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>
							<div className={style.flexdiv}>
								<input
									type="text"
									className={`form-control`}
									value={userPhone}
									onChange={ChangePhoneHandler}
								/>
								<button
									type="submit"
									className={`btn btn-primary ${style.flexbutton}`}
									disabled={changePhoneButtonDisabled}
									onClick={onSubmitChangePhone}
								>
									Изменить
								</button>
							</div>
						</ResultListItem>
					</ResultList>
				</StyledTable>

				{/*Formik?*/}
				<StyledTable>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>
							<Title3>Изменить пароль</Title3>
						</ResultListItem>
					</ResultList>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>&nbsp;</ResultListItem>
					</ResultList>
				</StyledTable>

				<Formik
					initialValues={values}
					validationSchema={updatePasswordSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{({
						values,
						errors,
						touched,
						isSubmitting,
						handleChange,
						setFieldTouched,
						setFieldValue,
						handleSubmit,
					}) => {
						const onChange = e => {
							e.persist()
							handleChange(e)
							setFieldTouched(e.target.name, true, false)
						}
						return (
							<StyledForm>
								<ResultList style={{ minWidth: 200 }}>
									<P>
										Пароль можно использовать для авторизации в паре с
										электронной почтой. Для этого установите пароль и
										электронную почту, если сейчас их нет.
									</P>
									<P>
										Без пароля будет доступна авторизация по ссылке,
										отправляемую на электронную почту.
									</P>
								</ResultList>
								<FormWrapper>
									<FormItem>
										<Label>Текущий пароль</Label>
										<div>
											<StyledTextField
												placeholder="оставьте пустым, если пароля нет"
												value={values.oldPassword}
												onChange={onChange}
												type="password"
												name="oldPassword"
												isInvalid={!!errors.oldPassword && touched.oldPassword}
											/>
											<FormControl.Feedback type="invalid">
												{errors.oldPassword}
											</FormControl.Feedback>
										</div>
									</FormItem>

									<FormItem>
										<Label>Новый пароль</Label>
										<div>
											<StyledTextField
												value={values.newPassword}
												onChange={onChange}
												placeholder="от 8 символов"
												type="password"
												name="newPassword"
												isInvalid={!!errors.newPassword && touched.newPassword}
											/>
											<FormControl.Feedback type="invalid">
												{errors.newPassword}
											</FormControl.Feedback>
										</div>
									</FormItem>
								</FormWrapper>

								<div style={{ margin: '0 auto' }}>
									<SubmitButton
										variant="primary"
										type="submit"
										disabled={!touched.newPassword}
									>
										Сменить
									</SubmitButton>
								</div>
							</StyledForm>
						)
					}}
				</Formik>

				<StyledTable>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>
							<Title3>Информация о тарифе</Title3>
						</ResultListItem>
						<ResultListItem>Начало периода</ResultListItem>
						<ResultListItem>Доступно новых заданий</ResultListItem>
						<ResultListItem>Ваш тариф</ResultListItem>
						<ResultListItem>Ежемесячная плата</ResultListItem>
					</ResultList>
					<ResultList style={{ minWidth: 200 }}>
						<ResultListItem>&nbsp;</ResultListItem>
						<ResultListItem>
							{moment(user.tariffStartDay).format('DD.MM.YYYY')}
						</ResultListItem>
						<ResultListItem>{userAvailableTasks ?? '∞'}</ResultListItem>
						<ResultListItem>{state.tariffTranslation}</ResultListItem>
						<ResultListItem>{state.tariffAmount}</ResultListItem>
					</ResultList>
				</StyledTable>

				{/*todo make promo affect the price and after registration*/}
				<TariffButton
					onClick={() => {
						confirmTariffHandler('free')
					}}
					disabled={userTariff === 'free'}
					variant="primary"
				>
					Отменить подписку
				</TariffButton>

				<StyledTable>
					<ResultList style={{ minWidth: 200 }}>
						<P>
							Тарифные планы различаются максимальным количеством заданий,
							которые могут пройти ваши клиенты за календарный месяц,
							возможностью создавать свои колоды и их количеством.
						</P>
						<P>
							Клиенты не смогут пройти ваши задания, если лимит вашего тарифного
							плана будет исчерпан.
						</P>
					</ResultList>
				</StyledTable>

				<StyledTariffContainer>
					<TariffCard>
						<TariffBody>
							<TariffTitle>Бесплатный</TariffTitle>
							<div>
								<TariffParagraph>
									Доступно 2 завершенных заданий за месяц
								</TariffParagraph>
								<TariffParagraph>Доступно 5 базовых колоды</TariffParagraph>
								<TariffParagraph>0 руб / месяц</TariffParagraph>
							</div>
							<TariffButton
								onClick={() => {
									if (config.paymentsDisabled) {
										showTempTariffLockedChangeHandler('free')
									} else {
										confirmTariffHandler('free')
									}
								}}
								disabled={userTariff === 'free'}
								variant="primary"
							>
								{userTariff === 'free' ? 'Текущий' : 'Сменить'}
							</TariffButton>
						</TariffBody>
					</TariffCard>
					<TariffCard style={{ margin: '0 1em' }}>
						<TariffBody>
							<TariffTitle>Расширенный</TariffTitle>
							<div>
								<TariffParagraph>
									Доступно 5 завершенных заданий за месяц
								</TariffParagraph>
								<TariffParagraph>Доступно 15 расширенных колод</TariffParagraph>
								<TariffParagraph>
									{tariffTranslationAndAmount('advanced').tariffAmount} руб /
									месяц
								</TariffParagraph>
							</div>
							<TariffButton
								onClick={() => {
									if (config.paymentsDisabled) {
										showTempTariffLockedChangeHandler('advanced')
									} else {
										confirmTariffHandler('advanced')
									}
								}}
								disabled={userTariff === 'advanced'}
								variant="primary"
							>
								{userTariff === 'advanced' ? 'Текущий' : 'Сменить'}
							</TariffButton>
						</TariffBody>
					</TariffCard>
					<TariffCard style={{ margin: '0 1em' }}>
						<TariffBody>
							<TariffTitle>Специалист</TariffTitle>
							<div>
								<TariffParagraph>
									Доступно 5 завершенных заданий за месяц
								</TariffParagraph>
								<TariffParagraph>
									Доступно 15 расширенных колод и возможность создать 2 своих
								</TariffParagraph>
								<TariffParagraph>
									{tariffTranslationAndAmount('specialist').tariffAmount} руб /
									месяц
								</TariffParagraph>
							</div>
							<TariffButton
								onClick={() => {
									if (config.paymentsDisabled) {
										showTempTariffLockedChangeHandler('specialist')
									} else {
										confirmTariffHandler('specialist')
									}
								}}
								disabled={userTariff === 'specialist'}
								variant="primary"
							>
								{userTariff === 'specialist' ? 'Текущий' : 'Сменить'}
							</TariffButton>
						</TariffBody>
					</TariffCard>
					<TariffCard>
						<TariffBody>
							<TariffTitle>Профи</TariffTitle>
							<div>
								<TariffParagraph>
									Доступно ∞ завершенных заданий за месяц
								</TariffParagraph>
								<TariffParagraph>
									Доступно 40+ расширенных колод и возможность создать ∞ своих
								</TariffParagraph>
								<TariffParagraph>
									{tariffTranslationAndAmount('pro').tariffAmount} руб / месяц
								</TariffParagraph>
							</div>
							<TariffButton
								onClick={() => {
									if (config.paymentsDisabled) {
										showTempTariffLockedChangeHandler('pro')
									} else {
										confirmTariffHandler('pro')
									}
								}}
								disabled={userTariff === 'pro'}
								variant="primary"
							>
								{userTariff === 'pro' ? 'Текущий' : 'Сменить'}
							</TariffButton>
						</TariffBody>
					</TariffCard>
				</StyledTariffContainer>
			</Card.Body>
		</Card>
	)
}

export default Profile
