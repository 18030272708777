import axios, { AxiosInstance } from 'axios'

export default class TinkoffService {
	_client: AxiosInstance = axios.create({
		timeout: 30000,
	})

	async _request(url, method, body) {
		const res = await this._client[method](url, body)
		return res.data
	}

	init = async obj =>
		this._request('https://securepay.tinkoff.ru/v2/Init', 'post', obj)
}
