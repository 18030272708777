import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Pagination from 'material-ui-flat-pagination'
import { Table } from 'react-bootstrap'

const Wrapper = styled.div``

const StyledTable = styled(Table)`
	margin-top: 1em;
`

const Thead = styled('thead')`
	th {
		padding: 1em;
		font-weight: bold;
	}
`

const Tbody = styled('tbody')``

const Trow = styled('tr')`
	height: 50px;

	${({ status }) =>
		status === 'done' &&
		css`
			background-color: #dff0d8;
		`}

	${({ status }) =>
		status === 'inProgress' &&
		css`
			background-color: #fcf8e3;
		`}
`

const TCell = styled('td')`
	vertical-align: middle !important;
	width: ${({ width }) => (width ? width : 'initial')};
	padding: 0 0.75rem !important;
`

const StyledPagination = styled(Pagination)`
	display: flex;
	justify-content: center;
	margin-bottom: 1em;
	button {
		border: 1px solid #ececec;
		border-radius: 0;
		border-left: none;

		&:first-child,
		&:last-child {
			border-radius: 5px;
		}

		&:first-child {
			border-left: 1px solid #ececec;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
`

const MainTable = ({ rows, columns, offset, pageAmount, handlePagination }) => {
	return (
		<Wrapper>
			<StyledTable bordered hover>
				<Thead>
					<Trow>
						{columns.map(column => (
							<th key={column.id}>{column.label}</th>
						))}
					</Trow>
				</Thead>

				<Tbody>
					{rows.map((row, index) => (
						<Trow status={row.status} key={row.id + index.toString()}>
							{columns.map(column => {
								const value = row[column.id]
								return (
									<TCell width={column.width} key={column.id}>
										{value}
									</TCell>
								)
							})}
						</Trow>
					))}
				</Tbody>
			</StyledTable>
			<StyledPagination
				offset={offset}
				limit={10}
				total={pageAmount * 10}
				onClick={(e, offset) => handlePagination(offset)}
			/>
		</Wrapper>
	)
}

MainTable.propTypes = {
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	offset: PropTypes.number.isRequired,
	pageAmount: PropTypes.number.isRequired,
	handlePagination: PropTypes.func.isRequired,
}

export default MainTable
