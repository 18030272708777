import React, { useContext } from 'react'
import jwtDecode from 'jwt-decode'
import { Route, Redirect, BrowserRouter } from 'react-router-dom'
import Loader from './Loader'
import StoreContext from './StoreContext'
import TerraServiceContext from './TerraServiceContext'
import LoginPage from './pages/LoginPage'
import SignUpPage from './pages/SignUpPage'
import ChangePasswordPage from './pages/ChangePasswordPage'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import config from '../config'

const PrivateRoute = ({
	path,
	meta = { isPublic: false, ableForLogged: true },
	Component,
}) => {
	const store = useContext(StoreContext)
	const terraService = useContext(TerraServiceContext)

	const withCaptcha = page => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			return page
		} else {
			return (
				<GoogleReCaptchaProvider reCaptchaKey={config.captchaKey}>
					{page}
				</GoogleReCaptchaProvider>
			)
		}
	}

	const {
		state: { user },
	} = store
	return (
		<Route
			path={path}
			exact
			render={() => {
				const { isPublic, ableForLogged } = meta

				if (user && !ableForLogged) return <Redirect to="/exercises" />
				if (isPublic && ableForLogged) return <Component />

				const accessToken = localStorage.getItem('accessToken')
				if (!accessToken) {
					let route = withCaptcha(<LoginPage />)

					if (path.includes('/login')) return route
					if (path.includes('/signup')) return withCaptcha(<SignUpPage />)
					if (path.includes('/change-password'))
						return withCaptcha(<ChangePasswordPage />)
					return route
				}

				if (accessToken && user !== null && !user) {
					store.login(null)
					terraService
						.getUser()
						.then(user => {
							store.login(user)
						})
						.catch(e => {
							store.login(false)
							localStorage.clear()
						})
				}

				if (user === null) return <Loader />
				if (!user) return <LoginPage />

				if (path === '/') return <Redirect to="/exercises" />

				return <Component />
			}}
		/>
	)
}

PrivateRoute.propTypes = {}

export default PrivateRoute
