import React, { useContext, useEffect, useState } from 'react'

import MainTable from './Table'
import TerraServiceContext from './TerraServiceContext'
import OperationButtons from './OperationButtons'
import StoreContext from './StoreContext'
import ExercisePreviewModal from './utils/ExercisePreviewModal'

const ExerciseTable = () => {
	const {
		state: { user },
		showLoading,
		hideLoading,
	} = useContext(StoreContext)

	const [state, setState] = useState({
		isOpenPreview: false,
		exercise: null,
	})

	const [exercises, setExercises] = useState([])
	const [pageAmount, setPageAmount] = useState(0)
	const [offset, setOffset] = useState(0)

	const terraService = useContext(TerraServiceContext)

	useEffect(() => {
		async function fetchExercises() {
			showLoading()
			const { exercises, pageAmount } = await terraService.getExercises({
				limit: 10,
				offset,
			})
			setExercises(exercises)
			setPageAmount(pageAmount)
			hideLoading()
		}

		fetchExercises()
	}, [offset])

	const setExercise = async exerciseId => {
		const exercise = await terraService.getExercise(exerciseId)
		setState({ ...state, isOpenPreview: true, exercise })
	}

	function createData(data) {
		return data.map(exercise => ({
			name: exercise.name,
			// todo если длинный?
			text: exercise.description,
			operation: (
				<OperationButtons
					actionTitle="Создать задание"
					id={exercise.id}
					preview={setExercise}
					hasChangePermission={
						user.id === exercise.userId || user.roles.includes('admin')
					}
					hasActionPermission={user.confirmed}
					editLink="exercise"
					exercise
				/>
			),
		}))
	}

	const columns = [
		{
			id: 'name',
			label: 'Название',
		},
		{
			id: 'text',
			label: 'Описание колоды',
		},
		{
			id: 'operation',
			label: 'Операции',
			width: '45%',
		},
	]
	const rows = createData(exercises)
	const { isOpenPreview, exercise } = state

	return (
		<div>
			{exercise && (
				<ExercisePreviewModal
					isOpen={isOpenPreview}
					exercise={exercise}
					closeModal={() =>
						setState({ ...state, isOpenPreview: false, exercise: null })
					}
				/>
			)}

			<MainTable
				columns={columns}
				rows={rows}
				pageAmount={pageAmount}
				offset={offset}
				handlePagination={offset => setOffset(offset)}
			/>
		</div>
	)
}

ExerciseTable.propTypes = {}

export default ExerciseTable
