import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import FormContainer from './FormContainer'
import { Formik, Form } from 'formik'
import { MenuItem, TextField, Tooltip } from '@material-ui/core'
import * as Yup from 'yup'
import DropZone from './DropZone'
import StepperInput from './StepperInput'
import TerraServiceContext from './TerraServiceContext'
import notify from '../utils/notify'
import { Button, Modal } from 'react-bootstrap'
import config from '../config'
import StoreContext from './StoreContext'
import { CheckBoxInput, CheckBoxLabel } from './SignUpForm'
import HasPermission from './utils/HasPermission'
import { withRouter } from 'react-router-dom'
import ym from 'react-yandex-metrika'

const FormWrapper = styled.div`
	gap: 1em;
	display: grid;
	padding: 1em;
`

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
	max-width: 670px;
	margin: 0 auto;
`

const FormItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const StyledTextField = styled(TextField)`
	width: 100vh;
	max-width: 230px;
`

const Label = styled.p`
	@media screen and (max-width: 440px) {
		margin-bottom: 1em;
	}
`

const StyledStepperInput = styled(StepperInput)`
	width: 100vh;
	max-width: 200px;
`

const SubmitButton = styled(Button)`
	align-self: center;
	padding: 0.5em 2em;
	margin: 1em;
`

const DeleteModal = styled(Modal)`
	color: black;
`

const exerciseSchema = Yup.object().shape({
	name: Yup.string()
		.required('Поле обязательно к заполнению')
		.test('len', 'Минимальная длина поля - 3 символа', val => val.length >= 3),
})

const ExerciseFrom = props => {
	const store = useContext(StoreContext)

	const stateValues = {
		name: '',
		description: '',
	}

	if (store.state.user.isAdmin) {
		stateValues['availableToAll'] = false
	}

	const [values, setValues] = useState(stateValues)

	const [modalState, setModal] = useState({
		showConfirm: false,
	})

	const [cards, setCards] = useState([])
	const [images, setImages] = useState([])
	const [deletedImages, setDeletedImages] = useState([])
	const [redirect, setRedirect] = useState(false)

	const [state, setState] = useState({
		cardsLimit: 1,
	})

	const { id } = useParams()

	useEffect(() => {
		async function fetchExercise() {
			try {
				store.showLoading()
				const exercise = await terraService.getExercise(id)
				store.hideLoading()
				const { availableToAll, description, name } = exercise
				setValues({
					availableToAll,
					description,
					name,
				})
				setImages(
					// these keys needs for sorting, deleting and adding cards on the backend
					// be careful with it, don't touch it without understanding the backend code and tons of tests
					exercise.cards.map(e => ({
						key: e.name,
						id: e._id,
						src: config.apiUrl + e.url,
						path: e.name,
						filename: e.filename,
						name: e.name,
					})),
				)
			} catch (e) {
				store.hideLoading()
				setRedirect(true)
			}
		}

		if (id) {
			fetchExercise()
		}
	}, [])

	const terraService = useContext(TerraServiceContext)

	const onSubmit = async values => {
		const formData = new FormData()

		Object.keys(values).forEach(key => {
			formData.append(key, values[key])
		})

		cards.forEach(file => {
			if (deletedImages.filter(img => img.src === file.src).length === 0) {
				if (!file.hasOwnProperty('key')) {
					formData.append('cards', file)
				}
			}
		})

		if (id) {
			formData.append(
				'cardsToDelete',
				JSON.stringify(deletedImages.filter(e => e.hasOwnProperty('key'))),
			)

			formData.append(
				'orderedCards',
				// do not include items to delete
				JSON.stringify(
					cards.filter(e => !deletedImages.map(e2 => e2.key).includes(e.key)),
				),
			)
		}

		try {
			const method = id ? 'updateExercise' : 'createExercise'
			store.showLoading()
			await terraService[method](formData, id)
			store.hideLoading()
			setRedirect(true)
			ym('reachGoal', 'EXERCISE_CREATED')
			notify(
				'success',
				id ? 'Колода измененой успешно' : 'Колода создано успешно',
			)
		} catch (e) {
			store.hideLoading()
			notify(
				'danger',
				id ? 'Не удалось изменить колоду' : 'Не удалось создать колоду',
			)
		}
	}

	const confirmDeleteHandler = async () => {
		try {
			store.showLoading()
			await terraService.deleteExercise(id)

			notify('success', 'Колода была удалена!')
			setRedirect(true)
		} catch (e) {
			if (e.response.data === 'tasks presents') {
				notify('danger', 'У вас есть созданные задания из этой колоды!')
			} else {
				notify('danger', 'Ошибка')
			}
			console.log(e)
		}

		setModal({ ...modalState, showConfirm: false })
		store.hideLoading()
	}

	const closeDeleteModal = () => setModal({ ...modalState, showConfirm: false })
	const openDeleteModal = () => setModal({ ...modalState, showConfirm: true })

	const { cardsLimit } = state

	if (redirect) return <Redirect to="/exercises" />

	// ym('reachGoal', 'EXERCISE_FORM_OPENED')

	return (
		<FormContainer title={id ? 'Изменение колоды' : 'Создание колоды'}>
			<DeleteModal show={modalState.showConfirm} onHide={closeDeleteModal}>
				<Modal.Header closeButton>
					<Modal.Title>Подтвердите!</Modal.Title>
				</Modal.Header>
				<Modal.Body>Вы точно хотите удалить эту колоду?</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={confirmDeleteHandler}>
						Удалить
					</Button>
					<Button variant="outline-secondary" onClick={closeDeleteModal}>
						Отменить
					</Button>
				</Modal.Footer>
			</DeleteModal>

			<Formik
				initialValues={values}
				validationSchema={exerciseSchema}
				onSubmit={onSubmit}
				validator={() => ({})}
				validateOnChange
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					isSubmitting,
					handleChange,
					setFieldTouched,
					setFieldValue,
					handleSubmit,
				}) => {
					const onChange = name => e => {
						e.persist()
						handleChange(e)
						setFieldTouched(name, true, false)
					}

					function getFiles(files) {
						setCards(files)
					}

					return (
						<StyledForm>
							<FormWrapper>
								<FormItem>
									<Label>Название</Label>
									<StyledTextField
										required
										placeholder="Например: Карты Деревья"
										size="small"
										name="name"
										variant="outlined"
										helperText={errors.name}
										error={Boolean(errors.name)}
										onChange={onChange('name')}
										value={values.name}
									/>
								</FormItem>

								<FormItem>
									<Label>Описание колоды</Label>
									<StyledTextField
										placeholder="Например: выберите две карты, которые вам нравятся"
										size="small"
										name="description"
										variant="outlined"
										multiline
										maxRows={3}
										helperText={touched.description ? errors.description : ''}
										error={touched.description && Boolean(errors.description)}
										onChange={onChange('description')}
										value={values.description}
									/>
								</FormItem>

								<DropZone
									getImagesCount={count =>
										setState({ ...state, cardsLimit: count })
									}
									images={images}
									getFiles={getFiles}
									onDelete={file => setDeletedImages([...deletedImages, file])}
								/>
							</FormWrapper>
							{/*todo: редактирование типа колоды из интерфейса для админа*/}
							<HasPermission>
								<CheckBoxLabel htmlFor="availableToAll">
									Доступно для всех
									<CheckBoxInput
										id="availableToAll"
										value={values.availableToAll}
										name="availableToAll"
										type="checkbox"
										checked={values.availableToAll}
										onChange={handleChange}
									/>
								</CheckBoxLabel>
							</HasPermission>
							<div style={{ margin: '0 auto' }}>
								<SubmitButton
									disabled={cards.length === 0}
									variant={cards.length === 0 ? 'outline-secondary' : 'primary'}
									type="submit"
								>
									{id ? 'Изменить' : 'Создать'}
								</SubmitButton>

								{id && (
									<SubmitButton variant="danger" onClick={openDeleteModal}>
										Удалить
									</SubmitButton>
								)}

								<SubmitButton
									variant="outline-secondary"
									onClick={props.history.goBack}
								>
									Отменить
								</SubmitButton>
							</div>
						</StyledForm>
					)
				}}
			</Formik>
		</FormContainer>
	)
}

export default withRouter(ExerciseFrom)
