import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { ResultList, ResultListItem, ResultTable } from '../TaskResult'
import { CardViewer } from '../DoTask/CardViewer'

const ExerciseModal = styled(Modal)`
	color: black;
`

const ExercisePreviewModal = ({ isOpen, closeModal, exercise }) => {
	return exercise ? (
		<ExerciseModal size="lg" show={isOpen} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>Просмотр колоды</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ResultTable>
					<ResultList>
						<ResultListItem>Колода</ResultListItem>
						<ResultListItem>Описание колоды</ResultListItem>
					</ResultList>
					<ResultList>
						<ResultListItem>{exercise.name}</ResultListItem>
						<ResultListItem>{exercise.description}</ResultListItem>
					</ResultList>
				</ResultTable>

				<CardViewer cardOnly={true} disabled={true} cards={exercise.cards} />
			</Modal.Body>
		</ExerciseModal>
	) : null
}

ExercisePreviewModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	exercise: PropTypes.object.isRequired,
}

export default ExercisePreviewModal
