import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from './Header'
import TerraServiceContext from '../TerraServiceContext'
import Loader from '../Loader'
import StyledContainer from '../../styled/StyledContainer'
import Carousel from './Carousel'
import { ConfirmModal } from '../SignUpForm'

const ExerciseWrapper = styled.div`
	margin-top: 1em;
`

const Wrapper = styled.div`
	width: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin: 0 auto;
`

const DoTask = () => {
	const [taskObject, setTask] = useState(null)
	const { id } = useParams()
	const terraService = useContext(TerraServiceContext)

	useEffect(() => {
		terraService
			.getTask(id)
			.then(res => {
				setTask(res)
			})
			.catch(e => {
				if (e.request.status === 400) {
					setTask({
						hasError: true,
						name: 'no task!',
					})
				} else {
					setTask({
						hasError: true,
						name: 'Ошибка!',
						description: 'Данного задания не существует',
					})
				}
			})
	}, [])

	if (taskObject === null) return <Loader />

	const { name, description, task } = taskObject

	const onCompleteHandler = (cards, callback) => {
		const completeddTask = {
			...taskObject,
			status: 'done',
			completed: new Date(),
			cards,
		}
		terraService.updateTask(completeddTask, taskObject.id).then(() => {
			setTask(completeddTask)
			if (callback) callback()
		})
	}

	const autoSaveHandler = (cards, callback) => {
		cards.forEach((card, index) => (card.index = index))

		terraService
			.updateTask(
				{
					...taskObject,
					status: 'inProgress',
					cards,
				},
				taskObject.id,
			)
			.then(res => {
				setTask(res)
				if (callback) callback()
			})
	}

	if (taskObject.hasError && taskObject.name === 'no task!') {
		return (
			<Wrapper>
				<ConfirmModal style={{ width: '100%' }}>
					<h3>Лимит заданий по тарифу</h3>
					<br />
					<h3>Сообщите это тому, кто дал вам эту ссылку</h3>
				</ConfirmModal>
			</Wrapper>
		)
	}

	let page

	if (taskObject.hasError) {
		page = null
	} else {
		page = (
			<ExerciseWrapper>
				<Carousel
					onCompleteHandler={onCompleteHandler}
					autoSaveHandler={autoSaveHandler}
					selectedCards={taskObject.cards}
					cardsAmount={taskObject.cardsAmount}
					status={taskObject.status}
					exerciseId={taskObject.exerciseId}
				/>
			</ExerciseWrapper>
		)
	}
	return (
		<StyledContainer>
			<Header name={name} description={description} task={task} />
			{page}
		</StyledContainer>
	)
}

DoTask.propTypes = {}

export default DoTask
