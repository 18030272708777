import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link, Redirect, useLocation } from 'react-router-dom'
import StoreContext from './StoreContext'
import TerraServiceContext from './TerraServiceContext'

const Wrapper = styled.div`
	height: 54px;
	background-color: #0575d9;
	display: flex;
	align-items: center;
	padding: 0 1em;
	border-radius: 5px;
	margin-top: 1em;
`

const Brand = styled.h1`
	font-weight: lighter;
	font-size: 1.5em;
	opacity: 0.9;
`

const Nav = styled('nav')`
	display: flex;
	width: 100%;

	a,
	a:hover {
		text-decoration: none;
		color: #fff;
	}
`

const NavItem = styled(Link)`
	font-weight: lighter;
	font-size: 1em;
	margin-left: ${({ isright }) => (isright ? 'auto' : '1em')};
	cursor: pointer;
	opacity: ${({ active }) => (active ? 1 : 0.7)};
`

const Header = () => {
	const { pathname } = useLocation()

	const {
		state: { user },
		logout,
		showLoading,
		hideLoading,
	} = useContext(StoreContext)

	const terraService = useContext(TerraServiceContext)

	const handleActiveNav = path => {
		return pathname.indexOf(path) !== -1
	}

	const logoutAction = async () => {
		showLoading()
		await terraService.userLogout()
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
		logout()
		hideLoading()
	}

	return (
		<Wrapper>
			<Brand>Terra</Brand>
			{user ? (
				<Nav>
					<NavItem to="/exercises" active={handleActiveNav('exercise')}>
						Колоды
					</NavItem>
					<NavItem to="/tasks" active={handleActiveNav('task')}>
						Задания
					</NavItem>

					<NavItem
						to="/profile"
						active={handleActiveNav('profile')}
						isright="true"
					>
						Мой профиль
					</NavItem>
					<NavItem to="/help" active={handleActiveNav('help')}>
						Помощь
					</NavItem>
					<NavItem to="" onClick={logoutAction} active={true}>
						Выйти
					</NavItem>
				</Nav>
			) : (
				<Nav>
					<NavItem
						to="/login"
						active={handleActiveNav('login') || pathname === '/'}
					>
						Вход
					</NavItem>
					<NavItem to="/signup" active={handleActiveNav('signup')}>
						Регистрация
					</NavItem>
				</Nav>
			)}
		</Wrapper>
	)
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
