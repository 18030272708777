import React, { useState, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import config from '../../config'
import {
	SelectedCard,
	SelectedCardDescription,
	SelectedCardImage,
	SelectedImageContainer,
} from './CardViewer'
import ImagePreview from '../utils/ImagePreview'
import CloseIcon from '@material-ui/icons/Close'
import StoreContext from '../StoreContext'

const SelectedCardCloseBadge = styled(CloseIcon)`
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(25%, -25%);
	z-index: 1;
	background: red;
	border-radius: 50%;
`

const DraggableCard = ({
	index,
	moveCard,
	error,
	id,
	removeSelectedCardHandler,
	card,
	cardsUpdateHandler,
}) => {
	const ref = useRef(null)
	const [, drop] = useDrop({
		accept: 'selectedCard',
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			if (dragIndex === hoverIndex) {
				return
			}
			const hoverBoundingRect = ref.current.getBoundingClientRect()
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			const clientOffset = monitor.getClientOffset()
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			moveCard(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'selectedCard', id, index },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	})
	const opacity = isDragging ? 0.5 : 1
	drag(drop(ref))

	return (
		<SelectedCard
			style={{ opacity }}
			ref={ref}
			index={index}
			id={id}
			moveCard={moveCard}
			className={'selectedCard'}
		>
			<Card.Body style={{ display: 'flex' }}>
				<SelectedCardCloseBadge
					onClick={() => removeSelectedCardHandler(card)}
				></SelectedCardCloseBadge>

				<SelectedImageContainer>
					<ImagePreview name={card.name} src={config.apiUrl + card.url}>
						<SelectedCardImage src={config.apiUrl + card.url} alt={card.name} />
					</ImagePreview>
				</SelectedImageContainer>

				<SelectedCardDescription
					placeholder="Дайте комментарий к этой карточке"
					className={'selectedCardDescription'}
					value={card.description}
					error={(!card.description || card.description === '') && error}
					required
					onChange={event => cardsUpdateHandler(card, event.target.value)}
				></SelectedCardDescription>
			</Card.Body>
		</SelectedCard>
	)
}

DraggableCard.propTypes = {
	index: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	moveCard: PropTypes.any.isRequired,
	removeSelectedCardHandler: PropTypes.func.isRequired,
	cardsUpdateHandler: PropTypes.func.isRequired,
	card: PropTypes.object.isRequired,
	error: PropTypes.bool.isRequired,
}

export { DraggableCard }
