import React from 'react'
import ReactDOM from 'react-dom'
import './utils/prototypes'

import App from './components/App'
import TagManager from 'react-gtm-module'

TagManager.initialize({ gtmId: 'GTM-5PRLJ6F' })

ReactDOM.render(<App />, document.getElementById('root'))
