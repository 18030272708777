import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from '../Header'
import { LoginForm } from '../LoginForm'
import { StyledContainer } from '../../styled'
import Footer from '../Footer'

const FromWrapper = styled.div`
	margin: 0 auto;
	max-width: 575px;
	margin-top: 15%;
`

const LoginPage = () => {
	return (
		<StyledContainer>
			<Header />
			<FromWrapper>
				<LoginForm />
			</FromWrapper>
			<Footer />
		</StyledContainer>
	)
}

LoginPage.propTypes = {}

LoginPage.defaultProps = {}

export default LoginPage
