import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import StoreContext from '../StoreContext'
import styled from 'styled-components'
import Loader from '../Loader'

const BackDrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1051;
	background: #0000004f;
`

const CenteredLoading = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	border-radius: 4px;
	padding: 15px 60px;
	-webkit-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);
	box-shadow: -2px 3px 5px -2px rgba(0, 0, 0, 0.75);

	div {
		height: auto;
	}
`

const LoadingComponent = () => {
	const {
		state: { loading },
	} = useContext(StoreContext)

	return loading ? (
		<BackDrop>
			<CenteredLoading>
				<Loader />
			</CenteredLoading>
		</BackDrop>
	) : null
}

LoadingComponent.propTypes = {}

export default LoadingComponent
