import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

const PreviewChildren = styled.div`
	height: 100%;
	width: 100%;
`

const PreviewContainer = styled.div`
	height: 100%;
	width: 100%;
`

const ModalImage = styled.img`
	margin: 0 auto;
	display: block;
	max-width: -webkit-fill-available;
	max-height: 70vh;
`

const PreviewModal = styled(Modal)`
	color: black;
`

const ImagePreview = props => {
	const [state, setState] = useState({
		open: false,
	})

	return (
		<PreviewContainer>
			<PreviewModal
				size="lg"
				show={state.open}
				onHide={() => setState({ ...state, open: false })}
			>
				<Modal.Header closeButton />
				<Modal.Body>
					<ModalImage src={props.src} alt="" />
				</Modal.Body>
			</PreviewModal>

			<PreviewChildren onClick={() => setState({ ...state, open: true })}>
				{props.children}
			</PreviewChildren>
		</PreviewContainer>
	)
}

ImagePreview.propTypes = {
	src: PropTypes.string,
	name: PropTypes.string,
}

export default ImagePreview
