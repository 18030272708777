import * as dotenv from 'dotenv'

dotenv.config()

export default {
	baseUrl: process.env.REACT_APP_BASE_URL,
	apiUrl: process.env.REACT_APP_API_URL,
	apiPath: process.env.REACT_APP_API_PATH,
	wssUrl: process.env.REACT_APP_WSS_URL,
	captchaKey: process.env.REACT_APP_CAPTCHA,
	paymentsDisabled: process.env.PAYMENTS_DISABLED === 'TRUE',
}
